<template>
	<div class="home">

		<section class="page-head no-bg">
			<div class="container">
				<div class="row align-items-center mb-6">
					<div class="col-lg-5 my-5 text-center text-lg-start">
						<h1 class="display-5">Never miss a domain expiry again</h1>
						<p class="lead my-4">Get info on any domain on the internet, then get notified when new changes are detected</p>
						<p class="mb-0">
							<button class="btn btn-primary px-5" @click="focusSearch">🔍 &nbsp; Search for a domain now</button>
						</p>
						<!--<search class="form-control-lg"></search>-->
					</div>
					<div class="col-lg-7 my-4">
						<img src="@/assets/domains-app.png" class="img-fluid" alt="Domains App search" />
					</div>
				</div>

			</div>
		</section>


		<section class="bg-white py-6">
			<div class="container">

				<h3 class="subtitle text-success fw-bold">📸 &nbsp; Domain monitoring</h3>
				<h2 class="mb-4">Get alerts for expiration, renewal, subdomains, and other changes</h2>

				<div class="row justify-content-center mb-5">
					<div class="col-sm-11 col-md-10 col-lg-8">

						<div class="domain-notifications rounded p-3 position-relative" :style="{'background-color': alertsBg}">
							<span class="badge bg-danger text-white position-absolute" style="top: 4px; right: 4px">⚪️ LIVE</span>

							<transition-group name="slide">
								<router-link v-for="(change) in recents" :key="`recent-change-${change.id}`" :to="`/${change.domain}`" class="d-block text-dark domain-alert">
									<div class="row">
										<div class="col">
											<h5>{{ change.domain }}</h5>
										</div>
										<div class="col-auto">
											<small>{{ change.date_string }}</small>
										</div>
									</div>

									<p class="mb-0">{{ change.text }} <span v-if="change.action" class="badge bg-info-lighter text-info text-capitalize">{{ change.action }} now</span></p>
								</router-link>
							</transition-group>

						</div>
					</div>
				</div>

				<h4 class="text-center my-4">To this day, we've run <span class="text-highlight my-2">{{ stats['monitoring-whois-checks'].toLocaleString() }}</span> checks and sent out <span class="text-highlight my-2">{{ stats['notifications-sent'].toLocaleString() }}</span> alerts.</h4>

				<p class="mb-0 text-center text-muted">
					<button class="btn btn-outline-info" @click="focusSearch">🔍 &nbsp; Search for a domain now</button>
					<span class="mx-2">or</span>
					<router-link to="/monitor-domains">Learn more</router-link>
				</p>

			</div>
		</section>


		<section class="bg-light py-5">
			<div class="container">

				<div class="row align-items-center">
					<div class="col-md-6 my-2 order-2 order-md-1">
						<h2 class="subtitle text-info fw-bold">🔎 &nbsp; Domain search</h2>
						<h1>Find the perfect domain</h1>
						<p class="lead">Search any keyword, and get instant stats on which extensions (TLDs) are available, for sale or taken.</p>

						<p class="mb-0 text-muted">
							<button class="btn btn-outline-info" @click="focusSearch">Search for a domain now</button>
							<span class="mx-2">or</span>
							<router-link to="/features/domain-search">Learn more</router-link>
						</p>
					</div>
					<div class="col-md-6 my-2 order-1 order-md-2">
						<video class="rounded" autoplay loop playsinline width="100%">
							<source src="https://files.dmns.app/domains-app-search.mp4" type="video/mp4">
						</video>
					</div>
				</div>

				

			</div>
		</section>


		<section class="bg-white py-6">
			<div class="container">

				<h2 class="text-center mb-5">Domains App can help you with:</h2>

				<div class="row mb-5">
					<div class="col-md-6 position-relative fancy-border fancy-border-yellow my-4">
						<div class="p-3 rounded bg-white">
							<h4 class="mb-3"><span class="me-2">📝</span> Organize your domains</h4>
							<p class="lead text-gray mb-1">Have a ton of domains? Keep lists of domains based on projects, brand or interest.</p>
							<p class="lead text-gray">Or even better, add private notes for each domain.</p>
							<p class="mb-0"><router-link to="/features/organise-domains">Learn more about lists &amp; notes</router-link></p>
						</div>
					</div>
					<div class="col-md-6 position-relative fancy-border fancy-border-purple my-4">
						<div class="p-3 rounded bg-white">
							<h4 class="mb-3"><span class="me-2">🧩</span> Domain info from your browser</h4>
							<p class="lead text-gray">Use the <router-link to="/browser-extension">Domain Info extension</router-link> directly in your browser to get owner details, subdomains list or other important infos about any domain on the internet.</p>
							<p class="mb-0"><router-link to="/browser-extension" class="text-success">Install extension</router-link></p>
						</div>
					</div>
				</div>

				<p class="text-center">
					<router-link to="/features" class="btn btn-lg btn-primary">Tour all the features →</router-link>
				</p>

			</div>
		</section>

		<section class="bg-black text-light py-6" style="background: #000">
			<div class="container">

				<h2 class="text-center">For developers</h2>
				<h4 class="text-center mb-5">Domain info as JSON 🤯 Really</h4>

				<div class="row mb-4">
					<div class="col-lg-5 my-3">
						<div class="p-3 rounded bg-dark mb-4">
							<h4 class="mb-3"><span class="me-2">📃</span> Domain info</h4>
							<p class="lead mb-0">Detailed info about your domain: <code>availability</code>, <code>creation date</code>, <code>expiry date</code>, <code>owner info</code> and more.</p>
						</div>
						<div class="p-3 rounded bg-dark mb-4">
							<h4 class="mb-3"><span class="me-2">💾</span> Domain WHOIS</h4>
							<p class="lead mb-0">Get WHOIS info formatted as JSON, to use directly in your app.</p>
						</div>
						<div class="p-3 rounded bg-dark mb-4">
							<h4 class="mb-3"><span class="me-2">🌍</span> DNS Records</h4>
							<p class="lead mb-0">Get a list of all DNS Records for a domain.</p>
						</div>
					</div>
					<div class="col-lg-7 my-3">
						<div class="card card-code-editor">
							<div class="card-body p-0 pt-">
								<div class="row ms-0">
									<div class="col-auto col-lines pt-2">
										<pre class="mb-2">1
2
3
4
5
6
7
8
9
10
11
12
13
14
15
16</pre>
									</div>
									<div class="col col-code pt-2">
										<pre>const api = require('domains-api')('api_key')

// Domain info with availability
// for-sale flags, enriched contacts, and more
let info = await api('that-domain.com', {
  detailed: true,
})

info.availability  // taken, available, sale
info.dates.created // standard Earth datetime
info.dates.expiry  // standard Earth datetime

// domain registrant
info.registrant.name
info.registrant.email
info.registrant.organization
</pre>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<p class="text-center">
					<a href="https://docs.dmns.app/api" class="btn btn-lg btn-primary">See API docs →</a>
				</p>

			</div>
		</section>

	</div>
</template>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.display-5 {
	font-size: 2.5rem;
}

@include media-breakpoint-down(md) {
	.display-5 {
		font-size: 2rem;
	}
}

.monitor-list-item {
	display: inline-block;
	margin-right: 10px;
}
.monitor-list-enter-active, .monitor-list-leave-active {
	transition: all 1s;
}
.monitor-list-enter {
	opacity: 0;
	transform: translateY(-30px);
}
.monitor-list-leave-to {
	opacity: 0;
	transform: translateY(30px);
}

.domain-example {
	//background-color: pink;

	&:hover {
		text-decoration: none;
		background-color: var(--primary-lighter);
	}
}

.domain-notifications {
	border: 1px solid #e2e8f0;
	background-color: #f1f5f9;
	height: 450px;
	max-height: 450px;
	overflow: hidden;
	transition: background-color 0.3s ease-in-out;

	.domain-alert {
		width: 80%;
		margin: 1rem auto;
		background-color: #fff;
		box-shadow: 0px 0px 12px rgba(0, 0, 0, .04);
		padding: 0.9rem;
		border-radius: 0.8rem;
		opacity: 1;
	}
}

.slide-enter-active {
  transition: all .3s ease;
}
.slide-enter {
  transform: scale(0.1);
  opacity: 0;
}

.fancy-border {

	&:before {
		position: absolute;
		content: "";
		background: #ccc;
		border-radius: 0.5rem;
		top: -1rem;
		left: 0rem;
		right: 2rem;
		height: 100%;
		opacity: 0.5;
		transition: opacity 0.2s ease-out;
	}

	&:hover:before {
		opacity: 1;
	}

	&.fancy-border-purple:before {
		background-image: linear-gradient(-45deg, #0D25B9, #FD6585);
	}

	&.fancy-border-blue:before {
		background-image: linear-gradient(45deg, #3C8CE7, #00EAFF);
	}

	&.fancy-border-green:before {
		background-image: linear-gradient(-45deg, #add100, #7b920a);
	}

	&.fancy-border-yellow:before {
		background-image: linear-gradient(45deg, #ffdd00, #fbb034);
	}

	& > div {
		position: relative;
		z-index: 100;
	}
}

.card-code-editor {
	background: #04293A;

	.col-lines {
		text-align: right;

		pre {
			color: $gray-700;
		}
	}

	.col-code {
		pre {
			color: $gray-200;
		}
	}
}
</style>

<script>
import { mapState } from 'vuex'

import domainsApi from "@/api.js"

//import Search from '@/components/Search.vue'
//import DomainActivityCard from '@/components/DomainActivityCard.vue'

export default {
	name: 'Home',
	components: {
		//DomainActivityCard
	},
	data() {
		return {
			recents: [],
			changes: [{
				domain: 'd.com',
				date: '1m ago',
				text: 'the change',
			}, {
				domain: 'example.com',
				date: '10m ago',
				text: 'the change',
			}, {
				domain: 'domain.com',
				date: '2 days ago',
				text: 'the change',
			}, {
				domain: 'that.app',
				date: 'a few days ago',
				text: 'the change',
			}],
			showTimer: null,
			alertsBg: '#f1f5f9',
		}
	},
	computed: {
		...mapState([ 'isMobile', 'stats' ]),
	},
	created() {
		this.$store.dispatch('updateStats')

		domainsApi.get('/monitoring/recent-changes').then(({ data }) => {
			this.sendAlert(data.pop())

			this.showTimer = setInterval(() => {
				this.sendAlert(data.length ? data.pop() : this.recents.pop())
			}, 2400)
		})
	},
	methods: {
		focusSearch() {
			const $search = document.querySelector(`.dmns-search.search-${this.isMobile ? '' : 'not-'}mobile`)
			$search.focus()
		},
		sendAlert(alert) {
			let color = '#f1f5f9'

			if (alert.type === 'sale') {
				color = '#f0fdf4'
			} else if (alert.action === 'register') {
				color = '#f0f9ff'
			} else if (alert.action === 'backorder') {
				color = '#ecfeff'
			} else if (alert.detected_changes.includes('transfer-completed')) {
				color = '#fff7ed'
			} else if (alert.detected_changes.includes('transfer-started')) {
				color = '#fffbeb'
			} else if (alert.detected_changes.includes('not-renewed')) {
				color = '#fef2f2'
			}

			this.alertsBg = color

			this.recents.unshift(alert)
		},
	},
	beforeDestroy() {
		clearInterval(this.showTimer)
	},
}
</script>
