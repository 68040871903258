<template>
	<div v-if="list" class="domains">

		<section class="page-head bg-transparent">

			<div class="position-relative px-3">
				<img :src="list.cover_photo_url || 'https://pbs.twimg.com/profile_banners/1331869898/1676652031/1500x500'" alt="Google Domains" class="list-cover-image rounded" height="250" />

				<img :src="list.logo_url || 'https://pbs.twimg.com/profile_images/1439878822531407872/TuADs5Yq_400x400.jpg'" alt="Google Domains" class="list-logo" width="125" height="125" />
			</div>

		</section>

		<section class="container-fluid py-4">

			<div class="row justify-content-between">
				<div class="col-12 col-lg-6 mb-2">
					<h2>
						{{ list.name }}
						<button v-if="isStaff || (auth && auth.id === list.person_id)" class="btn btn-sm btn-outline-primary mx-1" data-bs-toggle="modal" data-bs-target="#modal-collection-edit">Edit collection</button>
						<button v-if="isStaff" class="btn btn-sm btn-outline-danger mx-1" @click="listSync">Sync now</button>
					</h2>
					<p>
						<strong>{{ list.domains_count }}</strong> domains
						&middot;
						<span class="text-muted">by</span> <img :src="list.person.url_photo" :alt="list.person.name" height="18" class="rounded-circle" loading="lazy" /> {{ list.person.name }}
						<span v-if="list.status !== 'public' || (auth && list.person_id === auth.id)" class="text-info text-capitalize">&middot; {{ list.status }}</span>
					</p>

					<p class="mb-2">{{ list.description }}</p>

					<div v-if="list.filters">
						<p class="mb-1">
							New domains are being added with these filters:
						</p>
						<ul>
							<li v-for="filter in splitFilters(list.filters)" :key="`filter-${filter}`"><code>{{ filter }}</code></li>
						</ul>
					</div>
				</div>

				<div v-if="list.feats.includes('sales')" class="col-6 col-lg-3 col-xl-3 mb-2">
					<div class="card">
						<div class="card-body">
							<h5>{{ list.sales_count }} Sales</h5>

							<p class="card-text mb-2"><router-link :to="`/collections/${list.slug}/activity?activity-type=sale`">Sales volume: <strong>{{ list.sales_volume | currency }}</strong></router-link></p>
							<p class="card-text mb-2">Average price: <strong>{{ list.sales_volume / list.sales_count | currency }}</strong></p>
							<p class="card-text">Median price: <strong>{{ list.sales_median | currency }}</strong></p>
						</div>
						<div class="card-footer">
							<small class="text-muted">Sales data is imported from NameBio.com</small>
						</div>
					</div>
				</div>

				<div v-if="list.feats.includes('tlds')" class="col-6 col-lg-3 col-xl-3 mb-2">
					<div class="card">
						<div class="card-body">
							<h5>{{ list.tlds_count }} TLDs</h5>

							<div class="card-table mb-2">
								<table class="table table-hover table-sm">
									<tbody>
										<tr v-for="(tld, index) in tldsSorted.slice(0, states.allTlds || tldsSorted.length <= 4 ? 999 : 3)" :key="`list-tld-${tld[0]}`">
											<td><small class="text-muted">{{ index + 1 }}</small></td>
											<td><strong>{{ tld[0] }}</strong></td>
											<td>{{ tld[1] }}</td>
										</tr>
									</tbody>
								</table>
							</div>

							<p v-if="tldsSorted.length > 4" class="card-text cursor-pointer" @click="states.allTlds = !states.allTlds"><small class="text-muted">Show {{ states.allTlds ? 'less' : `${tldsSorted.length - 3} more` }}</small></p>
						</div>
					</div>
				</div>
			</div>

			<ul class="nav nav-tabs mb-2">
				<li class="nav-item">
					<router-link :to="`/collections/${list.slug}`" class="nav-link" :class="{active: !$route.params.tab}">Domains</router-link>
				</li>
				<li class="nav-item">
					<router-link :to="`/collections/${list.slug}/activity`" class="nav-link" :class="{active: $route.params.tab === 'activity'}">Activity</router-link>
				</li>

				<li v-if="list.feats.includes('sales') && list.sales_count > 10" class="nav-item">
					<router-link :to="`/collections/${list.slug}/sales`" class="nav-link" :class="{active: $route.params.tab === 'sales'}">Sales</router-link>
				</li>
			</ul>

			<div class="card">
				<div v-if="$route.params.tab === 'activity'" class="card-body">
					<div class="row mb-2">
						<div class="col-6 col-lg-3">
							<select class="form-select" v-model="filters2.type">
								<option :value="null">All activity types</option>
								<option value="sale">Sales</option>
								<option value="dns">DNS updates</option>
								<option value="whois">WHOIS changes</option>
							</select>
						</div>
					</div>

					<div class="card-table mb-3">
						<table class="table table-hover">
							<thead>
								<tr>
									<th>Domain</th>
									<th>Type</th>
									<th>Text</th>
									<th>When</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="activity in activities" :key="`activity-${activity.id}`">
									<td>
										<router-link :to="`/${activity.domain.domain}`" class="text-dark" style="font-size: 1.3rem">{{ activity.domain.domain }}</router-link>
									</td>
									<td>
										<span class="badge" :class="activityBadge[activity.type]">{{ activity.type }}</span>
									</td>
									<td>
										<a v-if="activity.type === 'url'" :href="activity.data.url" target="_blank" rel="noopener"><strong>{{ activity.data.title }}</strong> - {{ truncateString(activity.data.description, 75) }}</a>
										<span v-else>{{ activity.text || activity.data }}</span>
									</td>
									<td>{{ activity.created_at | dateLocal }}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="row">
						<div class="col-lg-2">
							<select class="form-select form-select-sm" v-model="pag2.limit">
								<option :value="10">10 per page</option>
								<option :value="50">50 per page</option>
								<option :value="100">100 per page</option>
							</select>
						</div>
						<div class="col">
							<ul class="pagination float-end my-0">
								<li v-if="pag2.pages > 10" class="page-item">
									<a class="page-link" @click="pag2.page = 1">First</a>
								</li>

								<li
									class="page-item"
									v-for="p in pag2.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag2.page }"
								>
									<a
										v-if="Math.abs(pag2.page - p) < 5 || pag2.page == pag2.pages - 1 || p == 0"
										class="page-link"
										@click="pag2.page = p"
									>
										{{ p }}
									</a>
								</li>

								<li v-if="pag2.pages > 10" class="page-item">
									<a class="page-link" @click="pag2.page = pag2.pages">Last</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div v-else-if="$route.params.tab === 'sales'" class="card-body">

					<div class="row">
						<div class="col">
							
						</div>
						<div class="col-auto">
							<select class="form-select form-select-sm" v-model="filtersSales.range">
								<option value="12-months">In last 12 months</option>
								<option value="5-years">In last 5 years</option>
								<option value="10-years">In last 10 years</option>
							</select>
						</div>
					</div>

					<stats-line-chart v-if="sales" :data="chartSales" :options="chartSalesOptions" :height="400" class="mb-4"></stats-line-chart>

					<div v-if="sales" class="row">
						<div class="col-lg-6">
							<h6>Top sales</h6>
							<pre>{{ sales.top10 }}</pre>
						</div>
						<div class="col-lg-6">
							<h6>Top venues</h6>
							<pre>{{ sales.byVenue }}</pre>
						</div>
					</div>
				</div>
				<div v-else class="card-body">

					<div class="card-table mb-3">
						<table class="table table-sm table-hover">
							<thead>
								<tr>
									<th class="hover cursor-pointer" @click="orderBy('domain', 'asc')">
										Domain <small v-if="order.order_by === 'domain'">{{ order.order === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th v-if="list.columns.includes('availability')">Availability</th>
									<th v-if="list.columns.includes('date_created')" class="hover cursor-pointer" @click="orderBy('date_created', 'asc')">
										<small>Created on</small> <small v-if="order.order_by === 'date_created'">{{ order.order === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th v-if="list.columns.includes('date_expiry')" class="hover cursor-pointer" @click="orderBy('date_expiry', 'asc')">
										Expires on <small v-if="order.order_by === 'date_expiry'">{{ order.order === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th v-if="list.columns.includes('registrant')">Registrant</th>
									<th v-if="list.columns.includes('registrar')">Registrar</th>
									<th v-if="list.columns.includes('ns')">Name Servers</th>
									<th v-if="list.columns.includes('activity')" class="hover cursor-pointer" @click="orderBy('activity_count', 'desc')">
										Changes <small v-if="order.order_by === 'activity_count'">{{ order.order === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th v-if="list.columns.includes('similar_web_rank')" class="hover cursor-pointer" @click="orderBy('similar_web_rank', 'asc')">
										Similarweb <small v-if="order.order_by === 'similar_web_rank'">{{ order.order === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="domain in domains" :key="`domain-${domain.id}`">
									<td>
										<router-link :to="`/${domain.domain}`" class="text-dark" style="font-size: 1.5rem">{{ domain.domain }}</router-link>
										<small v-if="list.domains.find(d => d.domain_id == domain.id).auto_added" class="ms-1" title="Added automatically to collection based on filters">⚪️</small>
									</td>
									<td v-if="list.columns.includes('availability')">
										<span class="badge text-capitalize me-1" :class="[badgeAvailability[domain.availability]]">{{ domain.availability }}</span>
										<span v-if="domain.availability === 'registered' && domain.services.buy.length" class="badge border border-primary text-primary">🛒 For sale</span>

										<p v-for="status in domain.status.filter(s => !s.startsWith('client') && !['serverdeleteprohibited', 'servertransferprohibited', 'serverupdateprohibited', 'ok', 'registered'].includes(s.toLowerCase()))" :key="`${domain.domain}-${status}`" class="mb-0">
											<span class="badge badge-sm bg-secondary-light text-secondary" :class="{'bg-red-100 text-red-500': status.toLowerCase() === 'pendingdelete'}">{{ status }}</span>
										</p>
									</td>
									<td v-if="list.columns.includes('date_created')">
										<p v-if="domain.date_created" class="mb-1">{{ domain.date_created | dateLocal }}</p>
										<small v-if="domain.date_created" class="text-muted">{{ domain.date_created | dateToNow(true) }}</small>
									</td>
									<td v-if="list.columns.includes('date_expiry')">
										<p v-if="domain.date_expiry" class="mb-1">{{ (new Date(domain.date_expiry)).toLocaleDateString() }}</p>
										<small v-if="domain.date_expiry" :class="{'text-muted': domain.expiryDays > 0, 'text-warning': domain.expiryDays <= 0}">{{ relativeDays(domain.expiryDays) }}</small>
									</td>
									<td v-if="list.columns.includes('registrant')">
										<p v-if="domain.availability === 'registered'" class="mb-1 text-break">👤 <span v-html="uniqueValues([domain.registrant.name, domain.registrant.organization], 'Anonymous')"></span></p>
										<p v-if="domain.availability === 'registered'" class="mb-0 text-break">📍 <span v-html="uniqueValues([domain.registrant.city, domain.registrant.stateOrProvince, domain.registrant.country], 'No location found')"></span></p>
									</td>
									<td v-if="list.columns.includes('registrar')">
										<span>{{ domain.registrar.name }}</span>
									</td>
									<td v-if="list.columns.includes('ns')">
										<span v-for="ns in domain.ns" :key="`dm-${domain.id}-ns-${ns}`" class="badge bg-light text-dark me-1 mb-1">{{ ns }}</span>
									</td>
									<td v-if="list.columns.includes('activity')">
										<router-link v-if="domain.activity_count" :to="`/${domain.domain}/history`" class="fw-bolder">{{ domain.activity_count }}</router-link>
										<small v-else class="text-muted">0</small>
									</td>
									<td v-if="list.columns.includes('similar_web_rank')">
										<a v-if="domain.similar_web_rank" :href="`https://www.similarweb.com/website/${domain.domain}/`" target="_blank">{{ domain.similar_web_rank.toLocaleString() }}</a>
										<small v-else class="text-muted">-</small>
									</td>
									<td>
										<span class="btn btn-sm me-1" :class="{ 'btn-primary': !domainsMonitoring.includes(domain.domain), 'btn-outline-primary': domainsMonitoring.includes(domain.domain) }" @click="monitorDomain(domain.domain)">{{ domainsMonitoring.includes(domain.domain) ? 'Monitoring' : '📸 Monitor' }}</span>

										<div v-if="domain.availability === 'available' && domain.services.buy.length" class="dropdown mt-1">
											<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Register</button>
											<div class="dropdown-menu dropdown-menu-right">
												<a v-for="registrar in domain.services.buy" :key="registrar.name" :href="registrar.url" class="dropdown-item" target="_blank"><span class="text-muted">at</span> {{ registrar.name }}</a>
											</div>
										</div>

										<div v-if="domain.availability === 'registered' && domain.services.backorder.length" class="dropdown mt-1">
											<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Backorder</button>
											<div class="dropdown-menu dropdown-menu-right">
												<a v-for="registrar in domain.services.backorder" :key="registrar.name" :href="registrar.url" class="dropdown-item" target="_blank"><span class="text-muted">at</span> {{ registrar.name }}</a>
											</div>
										</div>

										<a v-if="domain.availability === 'registered' && domain.services.buy.length" :href="domain.services.buy[0].url" class="btn btn-sm btn-outline-secondary mt-1" target="_blank">Buy at {{ domain.services.buy[0].name }}</a>

										<button v-if="isStaff && !domain.si" class="btn btn-sm btn-danger mt-2" @click="importSales(domain)">Import sales</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="row align-items-center">
						<div class="col-lg-2">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option :value="10">10 per page</option>
								<option :value="50">50 per page</option>
								<option :value="100">100 per page</option>
							</select>
						</div>
						<div class="col text-end">
							<ul class="pagination float-end my-0">
								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>

								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a
										v-if="Math.abs(pag.page - p) < 5 || pag.page == pag.pages - 1 || p == 0"
										class="page-link"
										@click="pag.page = p"
									>
										{{ p }}
									</a>
								</li>

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

		</section>

		<div class="modal fade" id="modal-collection-edit" tabindex="-1" role="dialog" aria-labelledby="modal-plan-label" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="listSave">
					<div class="modal-content border-0 shadow-sm">
						<div class="modal-header">
							<h5 class="modal-title" id="modal-plan-label">Edit collection</h5>
							<button type="button" class="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>

						<div class="modal-body">

							<div class="form-group mb-3">
								<label class="form-label" for="list-name">Collection name</label>
								<input type="text" id="list-name" class="form-control" v-model="list.name" required>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="list-description">Short description</label>
								<textarea id="list-description" class="form-control" rows="3" v-model="list.description"></textarea>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="list-status">Collection privacy</label>
								<select id="list-status" class="form-select" v-model="list.status">
									<option value="private">Private</option>
									<option value="unlisted">Unlisted</option>
									<option value="public">Public</option>
								</select>
							</div>

							<div v-if="isStaff" class="form-group mb-3">
								<label class="form-label" for="list-filters">Filters to auto-add domains</label>
								<textarea id="list-filters" class="form-control" rows="3" v-model="list.filters"></textarea>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="list-feats">Collection features</label>

								<div>
									<div class="form-check">
										<input class="form-check-input" type="checkbox" value="tlds" id="list-feats-tlds" v-model="list.feats">
										<label class="form-check-label" for="list-feats-tlds"><strong>TLDs</strong> - Display a section with breakdown of TLDs in collection</label>
									</div>

									<div class="form-check">
										<input class="form-check-input" type="checkbox" value="sales" id="list-feats-sales" v-model="list.feats">
										<label class="form-check-label" for="list-feats-sales"><strong>Sales</strong> - Display a section with sales stats</label>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="list-columns">Domains list columns</label>

								<div>
									<div v-for="(columnData, column) in domainColumns" :key="`list-columnss-${column}`" class="form-check">
										<input class="form-check-input" type="checkbox" :value="column" :id="`list-columns-${column}`" v-model="list.columns" :disabled="!columnData.enabled">
										<label class="form-check-label" :for="`list-columns-${column}`"><strong>{{ columnData.name }}</strong> - {{ columnData.text }}</label>
									</div>
								</div>
							</div>
						</div>

						<div class="modal-footer justify-content-between">
							<button type="button" class="btn btn-sm text-danger" @click="listDelete">Delete</button>

							<button class="btn btn-sm btn-primary">Save collection</button>
						</div>
					</div>
				</form>
			</div>
		</div>

	</div>
	<div v-else-if="states.list === 'loading'" class="text-center py-6">
		<span class="spinner-border spinner-border-sm"></span>
	</div>
	<div v-else-if="states.list === 'error'" class="text-center py-6 my-6">
		<p>We couldn't find the collection with url <code>{{ $route.params.list }}</code> 😬</p>
		<p><router-link to="/collections">Click here to view all collections</router-link></p>
	</div>
</template>

<style lang="scss" scoped>
.list-cover-image {
	width: 100%;
	height: 250px;
	object-fit: cover;
}

.list-logo {
	position: absolute;
	left: 2rem;
	bottom: -1rem;
	background-color: #fff;
	padding: 0.25rem;
	border-radius: 1rem;
}
</style>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { debounce } from 'vue-debounce'
import { format, parseISO } from 'date-fns'
import { Modal } from 'bootstrap'

import domainsApi from '@/api.js'
import { numberToCurrency, truncateString } from '@/utils.js'

import StatsLineChart from '@/components/StatsLineChart.vue'

export default {
	name: 'ListsList',
	components: { StatsLineChart },
	metaInfo() {
		return {
			title: `${this.list?.name || this.$route.params.list} domains collection`,
			meta: [
				{ vmid: 'description', name: 'description', content: this.list?.description },
				{ property: 'og:site_name', content: 'Domains App'},
				{ property: 'og:type', content: 'website' },
				{ property: 'og:title', content: this.list?.name },
				{ property: 'og:url', content: `https://dmns.app/collections/${this.list?.slug}` },
				{ property: 'og:description', content: this.list?.description },
				{ property: 'og:image', content: this.list?.cover_photo_url || 'https://pbs.twimg.com/profile_banners/1331869898/1594648752/1500x500' },
			]
		}
	},
	data() {
		return {
			states: {
				list: 'loading',
				domains: 'loading',
				activities: 'loading',
				sales: 'loading',
				allTlds: false,
			},
			activityBadge: {
				sale: 'bg-success-lighter text-success',
				note: 'bg-info-lighter text-info',
				url: 'bg-info-lighter text-info',
				whois: 'bg-warning-lighter text-warning',
			},

			list: null,

			pag: {
				page: 1,
				limit: 100,
				total: 0,
			},
			order: {
				order_by: 'domain',
				order: 'asc',
			},
			domains: [],

			pag2: {
				page: 1,
				limit: 100,
				total: 0,
			},
			filters2: {
				type: this.$route.query['activity-type'] || null,
			},
			order2: {
				order_by: 'domain',
				order: 'asc',
			},
			activities: [],

			filtersSales: {
				range: '12-months',
			},
			sales: null,
			chartSalesOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					sales: {
						type: 'linear',
						position: 'right',
						//min: 0,
						ticks: {
							//stepSize: 10,
						},
						grid: {
							display: false,
						},
					},
					salesPrice: {
						type: 'linear',
						position: 'right',
						display: false,
						ticks: {
							//stepSize: 10,
						},
						grid: {
							display: false,
						},
						stacked: true,
					},
					price: {
						type: 'linear',
						position: 'left',
						//min: 0,
						grid: {
							display: true,
						},
						ticks: {
							callback: function(value) {
								return numberToCurrency(value).replace('.00', '')
							},
						},
					},
					x: {
						grid: {
							display: false,
						},
					},
				},
			},
			chartSales: {
				labels: [],
				datasets: [{
					label: 'Sales',
					yAxisID: 'sales',
					data: [],
					borderColor: '#5e81f4',
					backgroundColor: '#C7CFFF',
					pointBorderColor: 'rgb(94, 129, 244)',
					pointBackgroundColor: 'rgb(94, 129, 244, 0.5)',
					fill: false,
					tension: 0.1,
					borderWidth: 2,
					pointBorderWidth: 1,
					pointRadius: 3,
					pointHoverBorderWidth: 2,
					pointHoverRadius: 5,
					segment: {
						borderDash: ctx => {
							const isLastSegment = ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.p1DataIndex

							return isLastSegment ? [6, 6] : undefined
						},
					},
				}, {
					label: '$$',
					yAxisID: 'price',
					data: [],
					borderColor: 'rgb(2, 206, 167)',
					backgroundColor: 'rgba(240, 255, 252, 0.3)',
					pointBorderColor: 'rgb(2, 206, 167)',
					pointBackgroundColor: 'rgb(2, 206, 167, 0.5)',
					fill: false,
					tension: 0.1,
					borderWidth: 2,
					pointBorderWidth: 1,
					pointRadius: 3,
					pointHoverBorderWidth: 2,
					pointHoverRadius: 5,
					segment: {
						borderDash: ctx => {
							const isLastSegment = ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.p1DataIndex

							return isLastSegment ? [6, 6] : undefined
						},
					},
				}]
			},

			$modalCollection: null,
			domainColumns: {
				availability: {
					name: 'Availability',
					text: 'Availability as registered/available/for-sale',
					enabled: true,
				},
				date_created: {
					name: 'Created on',
					text: 'Registration date for domain',
					enabled: true,
				},
				date_expiry: {
					name: 'Expires on',
					text: 'Expiry date for domain',
					enabled: true,
				},
				registrant: {
					name: 'Registrant',
					text: 'Domain owner (if available)',
					enabled: true,
				},
				registrar: {
					name: 'Registrar',
					text: 'Name & url of the registrar',
					enabled: true,
				},
				ns: {
					name: 'Nameservers',
					text: 'Current NameServers used',
					enabled: true,
				},
				activity: {
					name: 'Changes',
					text: 'The number of domain changes detected',
					enabled: true,
				},
				similar_web_rank: {
					name: 'Similarweb Rank',
					text: 'Domain rank in Similarweb',
					enabled: true,
				},
				domain_rating: {
					name: 'Domain Rating',
					text: 'Ahrefs DR',
					enabled: false,
				},
				search_volume: {
					name: 'Search volume',
					text: 'Number of monthly searches on Google',
					enabled: false,
				},
			},
		}
	},
	computed: {
		...mapGetters([ 'auth', 'isStaff' ]),
		...mapState([ 'account', 'domainsMonitoring', 'accountLists', 'badgeAvailability' ]),
		loadActivityDebounced() {
			return debounce(this.loadActivity, 100)
		},
		tldsSorted() {
			return Object.entries(this.list.tlds).sort((a, b) => b[1] - a[1])
		},
	},
	created() {
		this.loadList()
		this.$store.dispatch('accountInfo')
		this.$store.dispatch('domainsMonitoring')

		this.$store.dispatch('ev', { event: 'View Collection', data: { feature: 'Collections', list: this.$route.params.list } })
	},
	methods: {
		truncateString,

		loadList() {
			this.states.list = 'loading'

			domainsApi.get(`lists/${this.$route.params.list}?expand=person`).then(({ data }) => {
				this.list = data
				this.states.list = 'loaded'

				if (this.$route.params.tab === 'activity') {
					this.loadActivity()
				} else if (this.$route.params.tab === 'sales') {
					this.loadSalesStats()
				} else {
					this.loadDomains()
				}

				setTimeout(() => {
					this.$modalCollection = new Modal('#modal-collection-edit')
				}, 200)
			}, error => {
				this.states.list = 'error'
				Vue.toasted.error(`Error loading list details (${error.message})`)
			})
		},

		listSave() {
			domainsApi.put(`lists/${this.list.id}`, {
				name: this.list.name,
				description: this.list.description,
				status: this.list.status,
				filters: this.list.filters,
				feats: this.list.feats,
				columns: this.list.columns,
			}).then(() => {
				this.$modalCollection.hide()
				Vue.toasted.success(`Collection is updated`)
			}, error => {
				Vue.toasted.error(`Error saving list (${error.message})`)
			})
		},

		listSync() {
			domainsApi(`lists/${this.list.id}/sync`).then(({ data }) => {
				console.log('sync response', data)
				if (data.newDomains) {
					Vue.toasted.success(`Added ${data.newDomains} domains`)
				}

				this.domains = []
				this.activities = []
				this.loadList()
				Vue.toasted.show(`Sync done`)
			}, Vue.toasted.error)
		},

		listDelete() {
			if (confirm('For sure delete this list?')) {
				domainsApi.delete(`lists/${this.list.id}`).then(() => {
					this.$modalCollection.hide()

					Vue.toasted.show(`"${this.list.name}" collection is deleted`)
					this.$router.push('/collections')
				}, error => {
					Vue.toasted.error(`Couldn't delete the list (${error.message})`)
				})
			}
		},

		loadDomains() {
			console.log('load domains')
			this.states.domains = 'loading'

			const params = {
				list: this.list.id,
				limit: this.pag.limit,
				page: this.pag.page,
				sortBy: this.order.order_by,
				sortOrder: this.order.order,
			}

			domainsApi.get(`domains`, { params }).then(({ data, headers }) => {
				this.pag.total = parseInt(headers['x-total'], 10)
				this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)
				this.domains.push(...data)
				this.states.domains = 'loaded'
			}, error => {
				this.states.domains = 'error'
				const msg = error.response?.data?.message || error.response?.statusText || error.message

				Vue.toasted.error(`Error loading domains (${msg})`)
			})
		},
		orderBy(field, defaultOrder) {
			this.order.order = this.order.order_by == field ? (this.order.order === 'asc' ? 'desc' : 'asc') : defaultOrder
			this.order.order_by = field
			this.domains = []

			this.loadDomains()
		},

		loadSalesStats() {
			this.states.sales = 'loading'
			this.sales = null
			this.chartSales.labels = []
			this.chartSales.datasets[0].data = []
			this.chartSales.datasets[1].data = []

			const params = {
				list: this.list.id,
				range: this.filtersSales.range,
			}

			domainsApi.get(`stats/domain-sales`, { params }).then(({ data }) => {

				if (this.filtersSales.range.endsWith('years')) {
					for (const year in data.byYear) {
						this.chartSales.labels.push(year)
						this.chartSales.datasets[0].data.push(data.byYear[year].sales)
						this.chartSales.datasets[1].data.push(data.byYear[year].price)
					}
				} else {
					for (const month in data.byMonth) {
						this.chartSales.labels.push(format(parseISO(`${month}-01`), 'MMM yyyy'))
						this.chartSales.datasets[0].data.push(data.byMonth[month].sales)
						this.chartSales.datasets[1].data.push(data.byMonth[month].price)
					}
				}

				this.sales = data
				this.states.sales = 'loaded'
			}, error => {
				this.states.sales = 'error'
				Vue.toasted.error(`Error loading sales (${error})`)
			})
		},

		loadActivity() {
			console.log('load activity')
			this.states.activities = 'loading'

			const params = {
				...this.filters2,
				list: this.list.id,
				limit: this.pag2.limit,
				page: this.pag2.page,
				sortBy: this.order2.order_by,
				sortOrder: this.order2.order,
			}

			domainsApi.get(`activities`, { params }).then(({ data, headers }) => {
				this.pag2.total = parseInt(headers['x-total'], 10)
				this.pag2.pages = Math.ceil(this.pag2.total / this.pag2.limit)
				this.activities.push(...data)
				this.states.activities = 'loaded'
			}, error => {
				this.states.activities = 'error'
				Vue.toasted.error(`Error loading list activity (${error})`)
			})
		},
		orderBy2(field, defaultOrder) {
			this.order2.order = this.order2.order_by == field ? (this.order2.order === 'asc' ? 'desc' : 'asc') : defaultOrder
			this.order2.order_by = field
			this.activities = []

			this.loadActivity()
		},

		monitorDomain(domain) {
			if (!this.auth || (this.account.domains >= this.account.domains_limit && !this.domainsMonitoring.includes(domain))) {
				alert('plan upgrade needed')
			} else {
				this.$store.dispatch('toggleDomainMonitor', domain)
			}
		},
		importSales(domain) {
			domainsApi(`domain/${domain.domain}/import-sales`).then(({ data }) => {
				if (data.sales.length) {
					Vue.toasted.success(`${domain.domain} - ${data.message}`, {
						action: {
							text: 'View sales',
							href: `/${domain.domain}/history`
						},
					})
				}
				domain.si = 1
			})
		},

		splitFilters(sql) {
			return sql.split(/ or /i).map(filter => {

				if (filter.startsWith('ns')) {
					filter = filter.replace('ns', 'NS')
				}

				return filter.replace('LIKE', 'includes').replace(/%/g, '')
			})
		},
		relativeDays(days) {
			const rtf = new Intl.RelativeTimeFormat('en')

			if (days > 350 || days < -350) {
				return rtf.format(Math.round(days / 365), 'year')
			} else if (days > 28 || days < -28) {
				return rtf.format(Math.round(days / 30.4), 'month')
			} else {
				return rtf.format(days, 'day')
			}
		},
		uniqueValues(values, defaultValue) {
			if (defaultValue) {
				defaultValue = `<small><i class="text-muted">${defaultValue}</i></small>`
			}
			return [...new Set(values)].filter(Boolean).join(', ') || defaultValue
		},
	},
	watch: {
		'$route.params.tab'() {
			this.domains = []
			this.activities = []

			if (this.$route.params.tab === 'activity') {
				if (this.$route.query['activity-type'] && this.filters2.type !== this.$route.query['activity-type']) {
					this.filters2.type = this.$route.query['activity-type']
				} else {
					this.loadActivityDebounced()
				}
			} else if (this.$route.params.tab === 'sales') {
				this.loadSalesStats()
			} else {
				this.loadDomains()
			}
		},
		filters2: {
			deep: true,
			handler() {
				this.activities = []
				this.loadActivityDebounced()
			}
		},
		filtersSales: {
			deep: true,
			handler() {
				this.loadSalesStats()
			}
		},
		'pag.page'() {
			this.domains = []
			this.loadDomains()
		},
	},
}
</script>
