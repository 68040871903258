<template>

	<input type="search" id="search" class="form-control dmns-search" v-model="q" :placeholder="placeholder || 'running, twitter.com, .app'">

</template>

<style lang="scss">
@import "bootstrap/scss/functions";
@import 'bootstrap/scss/variables';

.dmns-search {
	border-radius: 1.5rem !important;
	border-color: var(--bs-gray-200) !important;
	box-shadow: none;

	&:hover {
		border-color: var(--bs-gray-300) !important;
	}

	&:focus {
		border-color: $purple-300 !important;
		box-shadow: none !important;
	}
}
</style>

<script>
//import { debounce } from 'vue-debounce'

export default {
	name: 'Search',
	props: ['query', 'placeholder', 'focusable'],
	components: {},
	data() {
		return {
			q: this.query || '',
		}
	},
	created() {
		//console.log('search', 'created')
	},
	mounted() {
		if (this.focusable && this.$route.name === 'Home') {
			this.$el.focus()
		}
	},
	methods: {
		
	},
	watch: {
		q(q, queryOld) {
			q = q.replace(/\s/g, '').replace('․', '.').toLowerCase().trim()

			if (q === queryOld.replace(/\s/g, '').toLowerCase().trim()) {
				return
			}

			if (!this.q.length) {
				this.$router.push('/')
			} else if (this.q.startsWith('.')) {
				this.$router.push({ path: '/tlds', query: { q } })
			} else {
				this.$router.push({ path: '/domains', query: { q } })
			}
		}
	}
}
</script>
