<template>
	<div class="tlds">

		<section class="page-head">
			<div class="container text-center py-5">
				<p class="lead text-muted mb-1">{{ tld ? tld.type : 'TLD' }} details</p>
				<h1 class="display-3 fw-bold mb-3">.{{ $route.params.tld }}</h1>
				<p v-if="tld" class="lead text-muted mb-1">
					<span v-if="tld.type === 'ccTLD'">Country TLD for {{ flag($route.params.tld) }} {{ name($route.params.tld) }}</span>
					<span v-else-if="tld.type === 'gTLD'">Commercial TLD</span>
					<span v-else-if="tld.type === 'grTLD'">Business TLD</span>

					<span v-if="tld.domains_registered"> &middot; {{ tld.domains_registered.toLocaleString() }} domains registered</span>
				</p>
			</div>
		</section>

		<section>

			<div class="container py-5">

				<nav class="text-center mb-4">
					<ul class="nav">
						<li class="nav-item">
							<a href="#tld-details" class="nav-link">TLD details</a>
						</li>
						<li class="nav-item">
							<a href="#tld-registry" class="nav-link">Registry info</a>
						</li>
						<li class="nav-item">
							<a href="#tld-registrars" class="nav-link">Registrars &amp; Pricing</a>
						</li>
						<li class="nav-item">
							<a href="#tld-domain-sales" class="nav-link">Domain sales</a>
						</li>
					</ul>
				</nav>

				<div class="row justify-content-center mb-5">

					<div class="col-lg-6">

						<div id="tld-details" class="card mb-5">
							<div class="card-body">
								<h4 class="mb-4">TLD Details</h4>

								<div v-if="tld" class="card-table">
									<table class="table table-striped table-hover table-borderless">
										<tbody>
											<tr>
												<td>Domains registered</td>
												<td>{{ tld.domains_registered.toLocaleString() }}</td>
											</tr>
											<tr>
												<td>Type</td>
												<td>{{ tld.type }}</td>
											</tr>
											<tr>
												<td>Entity/Country</td>
												<td>
													<span v-if="tld.type === 'ccTLD'">{{ flag($route.params.tld) }} {{ name($route.params.tld) }}</span>
													<span v-else-if="tld.type === 'gTLD'">Commercial</span>
													<span v-else-if="tld.type === 'grTLD'">Businesses</span>
													<span v-else>{{ tld.type }}</span>
												</td>
											</tr>
											<tr>
												<td>Date created</td>
												<td>{{ new Date(tld.created).toLocaleDateString() }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>


					<div class="col-lg-6">

						<div id="tld-registry" class="card mb-5">
							<div class="card-body">
								<h4 class="mb-4">
									<a href="https://support.google.com/domains/answer/6279305" target="_blank" class="float-end">ℹ️</a>
									Registry
								</h4>

								<div class="card-table">
									<table class="table table-striped table-hover table-borderless">
										<tbody v-if="tld">
											<tr>
												<td>Registry</td>
												<td>{{ tld.organization.organisation }}</td>
											</tr>
											<tr>
												<td>Registry address</td>
												<td>{{ tld.organization.address }}</td>
											</tr>
											<tr>
												<td>WHOIS Server</td>
												<td><code>{{ tld.whois }}</code></td>
											</tr>
											<tr>
												<td>Website</td>
												<td>{{ tld.remarks }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

					</div>

					<div class="col-12">
						<div id="tld-registrars" class="card mb-5">
							<div class="card-body">
								<h3 class="mb-3">
									<a href="https://www.cloudflare.com/learning/dns/glossary/what-is-a-domain-name-registrar/" target="_blank" class="float-end"><small>ℹ️</small></a>
									Registrars
								</h3>

								<div v-if="statsRegistrars">
									<h6>Top registrars for <code>.{{ tld.tld }}</code></h6>
									<stats-line-chart :data="chartRegistrars" :options="chartRegistrarsOptions" :height="350"></stats-line-chart>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12">

						<div id="tld-domain-sales" class="card mb-5">
							<div class="card-body">
								<h3>
									<a href="https://en.wikipedia.org/wiki/Domain_aftermarket" target="_blank" class="float-end">ℹ️</a>
									Aftermarket domain sales
								</h3>
								<p>Public sales above $100. Sales data are imported from <a href="https://namebio.com" target="_blank">NameBio.com</a></p>

								<div v-if="statsSales">
									<stats-line-chart :data="chartSales" :options="chartSalesOptions" :height="400" class="mb-5"></stats-line-chart>

									<div class="row">
										<div class="col-lg-6">
											<h5>Top sales in last 12 months</h5>

											<ul class="nicer-list">
												<li v-for="sale in statsSales.top10" :key="`top10-sale-${sale.text}`">
													<router-link :to="`/${sale.text.split(' sold ').shift().toLowerCase()}`">{{ sale.text }}</router-link>
												</li>
											</ul>
										</div>
										<div class="col-lg-6">
											<h5>Top venues</h5>

											<div v-for="(sales, venue, index) in statsSales.byVenue" :key="`tld-sales-venue-${venue}`" class="tld-venue-sales bg-light p-2 mb-1 rounded-1">
												<div class="row align-items-center">
													<div class="col-10">
														<div class="tld-venue-sales-bar" :style="{width: `${Math.max(sales / Object.values(statsSales.byVenue)[0] * 100, 1)}%`}"></div>
														<h6 v-if="index" class="my-1">{{ venue }}</h6>
														<h5 v-else class="my-1">{{ venue }}</h5>
													</div>
													<div class="col-2 text-end">
														<strong>{{ sales.toLocaleString() }}</strong>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>

					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-lg-8">
						<h4>What is an TLD?</h4>

						<p class="mb-2">A top-level domain is one of the domains at the highest level in the hierarchical Domain Name System of the Internet.</p>
						<p>The top-level domain names are installed in the root zone of the name space. For all domains in lower levels, it is the last part of the domain name, that is, the last non empty label of a fully qualified domain name. For example, in the domain name <code>www.example.com</code>, the top-level domain is <code>com</code>.</p>
					</div>
				</div>

			</div>

		</section>

	</div>
</template>

<style type="text/css" scoped>
.tld-venue-sales {
	position: relative;
}
.tld-venue-sales strong,
.tld-venue-sales h5,
.tld-venue-sales h6 {
	position: relative;
	z-index: 50;
}
.tld-venue-sales-bar {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 30%;
	background-color: #f5f3ff;
	z-index: 0;
}
</style>

<script>
import Vue from "vue"
import { flag, name } from 'country-emoji'
import { format, parseISO } from 'date-fns'

import domainsApi from "@/api.js"
import { numberToCurrency } from "@/utils.js"

import StatsLineChart from '@/components/StatsLineChart.vue'

export default {
	name: 'TLD',
	components: { StatsLineChart },
	metaInfo() {
		return {
			title: `${this.$route.params.tld} TLD details`,
		}
	},
	data() {
		return {
			state: 'idle',
			tld: null,
			statsRegistrars: null,
			statsSales: null,

			tldStatuses: {
				'general-availability': {
					name: 'Available',
					class: 'bg-success-lighter text-success'
				},
				'unknown': {
					name: 'Unknown',
					class: 'bg-warning-lighter text-warning'
				},
			},

			chartRegistrarsOptions: {
				//response: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						grid: {
							display: false,
						}
					}
				},
			},
			chartRegistrars: {
				labels: [],
				dats: {},
				datasets: []
			},

			chartSalesOptions: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				maintainAspectRatio: false,
				scales: {
					sales: {
						type: 'linear',
						position: 'right',
						//min: 0,
						ticks: {
							//stepSize: 10,
						},
						grid: {
							display: false,
						},
					},
					salesPrice: {
						type: 'linear',
						position: 'right',
						display: false,
						ticks: {
							//stepSize: 10,
						},
						grid: {
							display: false,
						},
						stacked: true,
					},
					price: {
						type: 'linear',
						position: 'left',
						//min: 0,
						grid: {
							display: true,
						},
						ticks: {
							callback: function(value) {
								return numberToCurrency(value).replace('.00', '')
							},
						},
					},
					x: {
						grid: {
							display: false,
						},
					},
				},
			},
			chartSales: {
				labels: [],
				datasets: [{
					label: 'Sales',
					yAxisID: 'sales',
					data: [],
					borderColor: '#5e81f4',
					backgroundColor: '#C7CFFF',
					pointBorderColor: 'rgb(94, 129, 244)',
					pointBackgroundColor: 'rgb(94, 129, 244, 0.5)',
					fill: false,
					tension: 0.1,
					borderWidth: 2,
					pointBorderWidth: 1,
					pointRadius: 3,
					pointHoverBorderWidth: 2,
					pointHoverRadius: 5,
					segment: {
						borderDash: ctx => {
							const isLastSegment = ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.p1DataIndex

							return isLastSegment ? [6, 6] : undefined
						},
					},
				}, {
					label: '$$ volume',
					yAxisID: 'price',
					data: [],
					borderColor: 'rgb(2, 206, 167)',
					backgroundColor: 'rgba(240, 255, 252, 0.3)',
					pointBorderColor: 'rgb(2, 206, 167)',
					pointBackgroundColor: 'rgb(2, 206, 167, 0.5)',
					fill: false,
					tension: 0.1,
					borderWidth: 2,
					pointBorderWidth: 1,
					pointRadius: 3,
					pointHoverBorderWidth: 2,
					pointHoverRadius: 5,
					segment: {
						borderDash: ctx => {
							const isLastSegment = ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.p1DataIndex

							return isLastSegment ? [6, 6] : undefined
						},
					},
				}, {
					label: 'Sales $100-$499',
					yAxisID: 'salesPrice',
					data: [],
					borderColor: 'rgb(224, 242, 254)',
					backgroundColor: '#f0f9ff',
					fill: true,
					tension: 0.1,
					borderWidth: 0,
					pointBorderWidth: 1,
					pointRadius: 2,
				}, {
					label: 'Sales $500-$999',
					yAxisID: 'salesPrice',
					data: [],
					borderColor: 'rgb(125, 211, 252)',
					backgroundColor: '#e0f2fe',
					fill: true,
					tension: 0.1,
					borderWidth: 0,
					pointBorderWidth: 1,
					pointRadius: 2,
				}, {
					label: 'Sales $1k-$5k',
					yAxisID: 'salesPrice',
					data: [],
					borderColor: '#60a5fa',
					backgroundColor: '#bae6fd',
					fill: true,
					tension: 0.1,
					borderWidth: 0,
					pointBorderWidth: 1,
					pointRadius: 2,
				}, {
					label: 'Sales $5k+',
					yAxisID: 'salesPrice',
					data: [],
					borderColor: '#2563eb',
					backgroundColor: '#5e81f4',
					fill: true,
					tension: 0.1,
					borderWidth: 0,
					pointBorderWidth: 1,
					pointRadius: 2,
				}]
			},
		}
	},
	created() {
		this.loadTld()
		this.loadStats()

		this.$store.dispatch('ev', {
			event: 'View TLD page',
			data: {
				tld: this.$route.params.tld,
			}
		})
	},
	methods: {
		alert,
		flag,
		name,

		loadTld() {
			this.state = 'loading'

			domainsApi.get(`tlds/${this.$route.params.tld}`).then(({ data }) => {
				this.tld = data
				this.state = 'idle'
			}, error => {
				this.state = 'error'
				Vue.toasted.error(`Error loading TLD details (${error.message})`)
			})
		},
		loadStats() {

			domainsApi(`stats/domain-sales?tld=${this.$route.params.tld}`).then(({ data }) => {
				for (const month in data.byMonth) {
					this.chartSales.labels.push(format(parseISO(`${month}-01`), 'MMM yyyy'))

					this.chartSales.datasets[0].data.push(data.byMonth[month].sales)
					this.chartSales.datasets[1].data.push(data.byMonth[month].price)

					this.chartSales.datasets[2].data.push(data.byMonth[month].sales100)
					this.chartSales.datasets[3].data.push(data.byMonth[month].sales500)
					this.chartSales.datasets[4].data.push(data.byMonth[month].sales1k)
					this.chartSales.datasets[5].data.push(data.byMonth[month].sales5k)
				}

				this.statsSales = data
			}, error => {
				Vue.toasted.error(`Error loading sales stats (${error.message})`)
			})

			if (this.$route.params.tld === 'com') {
				domainsApi('stats/top-com-registrars').then(({ data }) => {
					const allRegistrars = []

					data.forEach(stat => {
						this.chartRegistrars.labels.push(format(parseISO(stat.date), 'MMM yyyy'))

						stat.data.forEach(dat => {
							if (!allRegistrars.includes(dat.registrar)) {
								allRegistrars.push(dat.registrar)
								this.chartRegistrars.dats[dat.registrar] = {
									label: dat.registrar,
									data: [],
									borderColor: `#${Math.floor(Math.random()*16777215).toString(16)}`,
									fill: false,
									tension: 0.1,
									borderWidth: 2,
									pointBorderWidth: 1,
									pointRadius: 3,
									pointHoverBorderWidth: 2,
									pointHoverRadius: 5,
								}
							}
						})
					})

					// fill lines with numbers
					data.forEach(stat => {
						allRegistrars.forEach(registrar => {
							const hasData = stat.data.find(d => d.registrar === registrar)
							this.chartRegistrars.dats[registrar].data.push(hasData ? hasData.total : 0)
						})
					})

					this.chartRegistrars.datasets = Object.values(this.chartRegistrars.dats)
					this.statsRegistrars = data
				})
			}
		},
	},
	watch: {
		
	}
}
</script>
