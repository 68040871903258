<template>
	<router-link :to="`/collections/${list.slug}`" class="card card-link card-list">
		<div class="card-images">
			<img :src="list.cover_photo_url || 'https://pbs.twimg.com/profile_banners/1331869898/1676652031/1500x500'" :alt="`Domain collection '${list.name}'`" class="card-img-top" loading="lazy" />
			<img :src="list.logo_url || 'https://pbs.twimg.com/profile_images/1439878822531407872/TuADs5Yq_400x400.jpg'" alt="Google Domains" class="list-logo" width="85" height="85" loading="lazy" />
		</div>

		<div class="card-list-body mt-3">
			<p v-if="list.person" class="mb-2"><span class="text-muted">by</span> <img :src="list.person.url_photo" :alt="list.person.name" height="18" class="rounded-circle" loading="lazy" /> {{ list.person.name }}</p>
			<h3 class="card-title">{{ list.name }}</h3>

			<div class="card-list-body row">
				<div class="col">
					<p class="mb-1 subtitle text-muted">Domains</p>
					<h5 class="mb-0">{{ list.domains_count }}</h5>
				</div>
				<div class="col">
					<p class="mb-1 subtitle text-muted">TLDs</p>
					<h5 class="mb-0">{{ list.tlds_count }}</h5>
				</div>
				<div class="col">
					<p class="mb-1 subtitle text-muted">Sales</p>
					<h5 class="mb-0">{{ list.sales_count }}</h5>
				</div>
				<!--
				<div class="col">
					<p class="mb-1 subtitle">Sales $</p>
					<h4 class="mb-0">{{ list.sales_volume | currency }}</h4>
				</div>
				-->
			</div>
		</div>
	</router-link>
</template>

<style lang="scss" scoped>
.card-list {
	padding: 0.5rem;
	border-radius: 0.6rem;

	.card-images {
		position: relative;
	}

	.card-img-top {
		border-radius: 0.5rem;
		aspect-ratio: 2 / 1;
		object-fit: cover;
	}

	.list-logo {
		position: absolute;
		left: 0.5rem;
		bottom: -10px;
		background-color: #fbfbfb;
		padding: 0.15rem;
		border-radius: 0.8rem;
	}

	.card-title {
		font-size: 22px;
	}
}
</style>

<script>
export default {
	name: 'ListCard',
	props: {
		list: Object,
	},
}
</script>
