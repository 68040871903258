<template>
	<router-link :to="`/${activity.domain}/history`" class="card card-link bg-blue-100 hover on-hover" :class="{'bg-red-100': activity.text.includes('expiration phase')}" style="aspect-ratio: 2/1">
		<div class="card-body pb-1">
			<h4 class="card-title">{{ activity.domain }}</h4>
			<p class="card-text">{{ activity.text }}</p>
		</div>
		<div class="card-footer bg-transparent border-0">
			<div class="row">
				<div class="col">
					<strong>{{ activity.date_string }}</strong>
				</div>
				<div class="col-auto">
					<span class="text-muted">WHOIS</span>
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
export default {
	name: 'DomainActivityCard',
	props: {
		activity: Object,
	},
}
</script>
