<template>

	<div class="btn-group">
		<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="lists-dropdown" data-bs-toggle="dropdown">
			<template v-if="listed === 1">🗂 In 1 list</template>
			<template v-else-if="listed > 1">🗂 In {{ listed }} lists</template>
			<template v-else>📂 Add to list</template>
		</button>
		<div v-show="domain" class="dropdown-menu" aria-labelledby="lists-dropdown" style="z-index: 9999">
			<button v-for="list in accountLists" :key="list.id" class="dropdown-item" :class="{'bg-info-lighter': domain && list.domains.includes(domain.id)}" @click="toggleList(list, domain)">{{ domain && list.domains.includes(domain.id) ? '☑︎' : '☐' }} {{ list.name }}</button>
			<div v-if="accountLists.length" class="dropdown-divider"></div>
			<button class="dropdown-item" @click="createList(domain)">New list</button>
		</div>
	</div>

</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import domainsApi from "@/api.js"

export default {
	name: 'DomainMonitorButton',
	props: ['domain'],
	components: {},
	created() {
		this.$store.dispatch('accountInfo')
		//this.$store.dispatch('accountLists')
	},
	computed: {
		...mapGetters([ 'auth' ]),
		...mapState([ 'account', 'accountLists' ]),
		listed() {
			let listed = 0

			if (this.domain) {
				listed = this.accountLists.filter(list => list.domains.includes(this.domain.id)).length
			}

			return listed
		}
	},
	methods: {
		createList(domain) {
			const name = prompt('List name', 'Domains to watch')

			if (name) {
				domainsApi.post('lists', { name }).then(({ data }) => {
					Vue.toasted.show(`"${name}" list is created`, {
						action: { text: 'View list', push: `/collections/${data.slug}` }
					})

					this.$store.commit('accountListsAdd', [ data ])
					this.toggleList(data, domain)
				}, error => {
					Vue.toasted.error(`Error creating list: ${error}`)
				})
			}
		},
		toggleList(list, domain) {
			this.$store.dispatch('listToggleDomain', { list, domain })
		},
	},
}
</script>
