<template>
	<div class="domains">

		<section class="page-head">
			<div class="container text-center py-3 py-lg-4">
				<h5 class="mb-0">{{ params.monitoring ? 'Domains in watchlist' : 'Domains in database' }}</h5>
			</div>
		</section>

		<section class="container-fluid py-4">

			<div class="row">
				<div class="col-3">

					<div class="mb-4">
						<button v-if="accountListSelected" class="btn btn-sm float-end" @click="editList(accountListSelected)">✏️ Edit this list</button>
						<label for="filter-tld" class="form-label subtitle">Lists:</label>

						<select class="form-select form-select-lg" v-model="params.list">
							<option value="">All domains (no list)</option>
							<optgroup label="Featured lists">
								<option v-for="list in publicLists" :key="list.id" :value="list.id">{{ list.name }} ({{ list.domains_count }})</option>
							</optgroup>
							<optgroup label="Your lists">
								<option v-for="list in accountLists" :key="list.id" :value="list.id">{{ list.name }} ({{ list.domains_count }})</option>
							</optgroup>
						</select>
					</div>

					<!--
					<div class="form-group mb-4">
						<label for="filter-tld" class="subtitle">Lists:</label>

						<div class="row row-cols-2 no-gutters">
							<div v-for="list in accountLists" :key="list.id" class="col pe-2 my-1">
								<div class="card h-100 border on-parent shadow-sm" :class="{'bg-light border-primary-lighter': list.id != params.list, 'bg-primary-lighter ': list.id == params.list}" @click="params.list = params.list == list.id ? '' : list.id">
									<div class="card-body py-1 px-2">
										<p class="card-text mb-2">
											<button class="btn btn-sm btn-link float-end show-on-hover" @click.stop="editList(list)">⚙️</button>
											{{ list.name }}
										</p>
										<p class="card-text">
											<small class="text-muted">{{ list.domains_count }} domains</small>
											<small v-if="list.public" class="badge badge-light-info ms-1">public</small>
										</p>
									</div>
								</div>
							</div>

							<div v-for="list in publicLists" :key="list.id" class="col pe-2 my-1">
								<div class="card h-100 border on-parent shadow-sm" :class="{'bg-light border-primary-lighter': list.id != params.list, 'bg-primary-lighter ': list.id == params.list}" @click="params.list = params.list == list.id ? '' : list.id">
									<div class="card-body py-1 px-2">
										<p class="card-text mb-2">{{ list.name }}</p>
										<p class="card-text">
											<small class="text-muted">{{ list.domains_count }} domains</small>
											<small v-if="list.public" class="badge badge-light-info ms-1">public</small>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					-->

					<div class="mb-3">
						<label for="filter-monitoring" class="subtitle">Monitoring:</label>
						<div class="form-check form-switch">
							<input type="checkbox" class="form-check-input" v-model="params.monitoring" id="filter-monitoring" />
							<label class="form-check-label" for="filter-monitoring">Domains I monitor</label>
						</div>
					</div>

					<div class="mb-3">
						<label for="filter-availability" class="subtitle">Availability:</label>
						<div class="cols">
							<label class="badge my-0 me-1" :class="{'bg-light-secondary text-secondary': params.availability !== '', 'bg-blue-100 text-blue-500': params.availability === ''}"><input type="radio" name="filter-availability" class="d-none" v-model="params.availability" value="" id="filter-availability" /> All</label>
							<label class="badge my-0 me-1" :class="{'bg-secondary-light text-secondary': params.availability !== 'available', 'bg-green-100 text-success': params.availability === 'available'}"><input type="radio" name="filter-availability" class="d-none" v-model="params.availability" value="available" /> Available</label>
							<label class="badge my-0 me-1" :class="{'bg-secondary-light text-secondary': params.availability !== 'registered', 'bg-blue-100 text-blue-500': params.availability === 'registered'}"><input type="radio" name="filter-availability" class="d-none" v-model="params.availability" value="registered" /> Registered</label>
							<label class="badge my-0 me-1" :class="{'bg-secondary-light text-secondary': params.availability !== 'for-sale', 'border border-primary text-primary': params.availability === 'for-sale'}"><input type="radio" name="filter-availability" class="d-none" v-model="params.availability" value="for-sale" /> For Sale</label>
						</div>
					</div>

					<div class="mb-3">
						<label for="filter-tld" class="subtitle">TLD:</label>
						<div class="cols">
							<label class="badge my-0 me-1" :class="{'bg-light-secondary text-secondary': params.tld !== '', 'bg-blue-100 text-blue-500': params.tld === ''}"><input type="radio" name="filter-tld" class="d-none" v-model="params.tld" value="" id="filter-tld" /> All</label>
							<label v-for="tld in tlds" :key="tld" class="badge my-0 me-1" :class="{'bg-secondary-light text-secondary': tld !== params.tld, 'bg-blue-100 text-blue-500': tld === params.tld}"><input type="radio" name="filter-tld" class="d-none" v-model="params.tld" :value="tld" /> {{ tld }}</label>
						</div>
					</div>

					<div class="mb-3">
						<label for="filter-status" class="subtitle">Status:</label>
						<div class="cosl">
							<label class="badge my-0 me-1" :class="{'bg-light-secondary text-secondary': params.status !== '', 'bg-blue-100 text-blue-500': params.status === ''}"><input type="radio" name="filter-status" v-model="params.status" class="d-none" value="" id="filter-status" /> All</label>
							<label v-for="status in statuses" :key="status" class="badge my-0 me-1" :class="{'bg-secondary-light text-secondary': status !== params.status, 'bg-blue-100 text-blue-500': status === params.status}"><input type="radio" name="filter-status" class="d-none" v-model="params.status" :value="status" /> {{ status }}</label>
						</div>
					</div>

					<div class="mb-3">
						<label for="filter-length" class="subtitle">Keyword length:</label>
						<vue-slider v-model="params.length" :min="1" :max="11" :lazy="true" contained="true" tooltip="none" :marks="{1: 1, 3: 3, 5: 5, 7: 7, 9: 9, 11: '10+'}"></vue-slider>
					</div>
				</div>
				<div class="col">

					<div class="row">
						<div class="col-12 col-lg-6">
							<p>
								<small v-if="state === 'idle' && pag.total > 35000">← Found <strong>many domains</strong>. Try some filters</small>
								<small v-else-if="state === 'idle'">Found <strong>{{ pag.total }} {{ ['available', 'registered'].includes(params.availability) ? params.availability : '' }} domains</strong></small>
							</p>
						</div>
						<div class="col-12 col-lg-6 text-end">
							<small class="text-muted me-1">Order by</small>
							<select class="form-control form-control-sm d-inline-block w-auto" v-model="pag.sortBy">
								<option value="domain">Alphabetically</option>
								<option value="length">Keyword length</option>
								<option value="date_expiry">Expires soon</option>
								<option v-if="isStaff" value="monitoring">Follows</option>
							</select>
						</div>
					</div>

					<div v-if="state === 'loading'" class="text-center mb-3">
						<div class="spinner-border" role="status"></div>
					</div>

					<table v-if="state === 'idle'" class="table bg-white rounded">
						<thead>
							<tr>
								<th>
									Domain <span v-if="pag.sortBy === 'domain'">{{ pag.sortOrder === 'asc' ? '▲' : '▼' }}</span>
								</th>
								<th>Availability</th>
								<th>
									Expires on <span v-if="pag.sortBy === 'date_expiry'">{{ pag.sortOrder === 'asc' ? '▲' : '▼' }}</span>
								</th>
								<th>Registrant</th>
								<th>
									<span v-if="pag.sortBy === 'monitoring'">{{ pag.sortOrder === 'asc' ? '▲' : '▼' }}</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="domain in domains" :key="domain.id">
								<td>
									<router-link :to="`/${domain.domain}`" class="text-dark" style="font-size: 1.5rem">{{ domain.domain }}</router-link>
								</td>
								<td>
									<span class="badge text-capitalize me-1" :class="[badgeAvailability[domain.availability]]">{{ labelAvailability[domain.availability] || domain.availability }}</span>
									<span v-if="domain.availability === 'registered' && domain.services.buy.length" class="badge border border-primary text-primary">🛒 For sale</span>

									<p v-for="status in domain.status.filter(s => !s.startsWith('client') && !['serverdeleteprohibited', 'servertransferprohibited', 'serverupdateprohibited', 'ok', 'registered'].includes(s.toLowerCase()))" :key="`${domain.domain}-${status}`" class="mb-0">
										<span class="badge badge-sm bg-secondary-light text-secondary" :class="{'bg-red-100 text-red-500': status.toLowerCase() === 'pendingdelete'}">{{ status }}</span>
									</p>
								</td>
								<td>
									<p v-if="domain.date_expiry" class="mb-1">{{ (new Date(domain.date_expiry)).toLocaleDateString() }}</p>
									<small v-if="domain.date_expiry" :class="{'text-muted': domain.expiryDays > 0, 'text-warning': domain.expiryDays <= 0}">{{ relativeDays(domain.expiryDays) }}</small>
								</td>
								<td>
									<p v-if="domain.availability === 'registered'" class="mb-1 text-break">👤 <span v-html="uniqueValues([domain.registrant.name, domain.registrant.organization], 'Anonymous owner')"></span></p>
									<p v-if="domain.availability === 'registered'" class="mb-0 text-break">📍 <span v-html="uniqueValues([domain.registrant.city, domain.registrant.stateOrProvince, domain.registrant.country], 'No location found')"></span></p>
								</td>
								<td>
									<p v-if="isStaff" class="mb-1">
										<span class="badge bg-dark">{{ domain.monitoring }}</span>
									</p>

									<span class="btn btn-sm me-1" :class="{ 'btn-primary': !domainsMonitoring.includes(domain.domain), 'btn-outline-primary': domainsMonitoring.includes(domain.domain) }" @click="monitorDomain(domain.domain)">{{ domainsMonitoring.includes(domain.domain) ? 'Monitoring' : '📸 Monitor' }}</span>

									<div v-if="domain.availability === 'available' && domain.services.buy.length" class="dropdown mt-1">
										<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Register</button>
										<div class="dropdown-menu dropdown-menu-right">
											<a v-for="registrar in domain.services.buy" :key="registrar.name" :href="registrar.url" class="dropdown-item" target="_blank"><span class="text-muted">at</span> {{ registrar.name }}</a>
										</div>
									</div>

									<div v-if="domain.availability === 'registered' && domain.services.backorder.length" class="dropdown mt-1">
										<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Backorder</button>
										<div class="dropdown-menu dropdown-menu-right">
											<a v-for="registrar in domain.services.backorder" :key="registrar.name" :href="registrar.url" class="dropdown-item" target="_blank"><span class="text-muted">at</span> {{ registrar.name }}</a>
										</div>
									</div>

									<a v-if="domain.availability === 'registered' && domain.services.buy.length" :href="domain.services.buy[0].url" class="btn btn-sm btn-outline-secondary mt-1" target="_blank">Buy at {{ domain.services.buy[0].name }}</a>
								</td>
							</tr>
							<tr v-if="!domains.length">
								<td colspan="6">
									<p class="text-muted text-center">No domains found.. Try changing the filters</p>
								</td>
							</tr>
						</tbody>
					</table>

				</div>
			</div>

		</section>

		<div v-if="auth" class="modal fade" id="modal-list" tabindex="-1" role="dialog" aria-labelledby="modal-list-label" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="editingList" class="modal-content border-0 shadow-sm">
					<div class="modal-header">
						<h5 class="modal-title" id="modal-list-label">Edit list "{{ editingList.name }}"</h5>
						<button type="button" class="btn-close text-muted" data-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<form>
							<div class="form-group row">
								<label class="col-sm-3 col-form-label">List name:</label>
								<div class="col-sm-9">
									<input type="text" class="form-control" v-model="editingList.name" />
								</div>
							</div>
							<div class="form-group row">
								<label class="col-sm-3 col-form-label">Public:</label>
								<div class="col-sm-9">
									<div class="form-check">
										<input class="form-check-input" type="checkbox" v-model="editingList.public" value="1" id="list-public">
										<label class="form-check-label" for="list-public"><strong>Yes</strong> - Others can see the list on website</label>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button class="btn btn-sm text-danger me-auto" @click="deleteList(editingList)">Delete this list</button>
						<button class="btn btn-sm btn-primary" @click="saveList">Save</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
//import Search from '@/components/Search.vue'
//import DomainMonitorButton from '@/components/DomainMonitorButton.vue'
//import domainsApi from "@/api.js"
import { debounce } from 'vue-debounce'
import Vue from 'vue'
import domainsApi from '@/api.js'
import { mapGetters, mapState } from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { Modal } from 'bootstrap'

export default {
	name: 'TLDs',
	components: { VueSlider },
	data() {
		return {
			labelAvailability: {
				registered: 'taken',
			},
			tlds: ['com', 'net', 'org', 'co', 'io', 'app', 'dev', 'info', 'link', 'me', 'xyz', 'uk', 'co.uk', 'de', 'ro', 'us'],
			statuses: ['pendingDelete', 'pendingTransfer', 'redemptionPeriod'],
			state: 'idle',
			domains: [],
			pag: {
				page: 1,
				total: 0,
				sortBy: 'domain',
				sortOrder: 'asc',
			},
			params: {
				availability: this.$route.query.availability || '',
				tld: this.$route.query.tld || '',
				length: [1, 11],
				status: this.$route.query.status || '',
				monitoring: this.$route.query.monitoring == 1 || this.$route.query.monitoring == 'true',
				list: this.$route.query.list || '',
				ns: this.$route.query.ns || '',
				registrar: this.$route.query.registrar || '',
				registrant: this.$route.query.registrant || '',
			},
			editingList: null,
			modalList: null
		}
	},
	created() {
		//console.log('TLDs', 'created')
		//this.query = this.$route.query.d

		this.loadDomains()
		this.$store.dispatch('accountInfo')
		this.$store.dispatch('domainsMonitoring')
		this.$store.dispatch('ev', { event: 'View Domains List', data: this.params })
	},
	methods: {
		alert,
		uniqueValues(values, defaultValue) {
			if (defaultValue) {
				defaultValue = `<small><i class="text-muted">${defaultValue}</i></small>`
			}
			return [...new Set(values)].filter(Boolean).join(', ') || defaultValue
		},
		loadDomains() {
			this.state = 'loading'

			domainsApi.get(`domains`, { params: { ...this.params, sortBy: this.pag.sortBy, sortOrder: this.pag.sortOrder } }).then(({ data, headers }) => {
				this.pag.total = parseInt(headers['x-total'], 10)

				this.domains.push(...data)
				this.state = 'idle'
			}, error => {
				this.state = 'error'
				Vue.toasted.show(`Error loading domains: ${error}`, { type: 'error' })
			})
		},
		monitorDomain(domain) {
			if (!this.auth || (this.account.domains >= this.account.domains_limit && !this.domainsMonitoring.includes(domain))) {
				alert('plan upgrade needed')
			} else {
				this.$store.dispatch('toggleDomainMonitor', domain)
			}
		},
		
		editList(list) {
			this.editingList = list
			this.modalList = new Modal('#modal-list')
			this.modalList.show()
		},
		saveList() {
			domainsApi.put(`lists/${this.editingList.id}`, {
				name: this.editingList.name,
				public: this.editingList.public ? 1 : 0,
			}).then(() => {
				Vue.toasted.show(`List "${this.editingList.name}" is saved`)
				this.modalList.hide()
			}, error => {
				Vue.toasted.show(`Error updating list: ${error}`, { type: 'error' })
			})
		},
		deleteList(list) {
			if (confirm('For sure delete this list?')) {
				domainsApi.delete(`lists/${list.id}`).then(() => {
					Vue.toasted.show(`List "${list.name}" is deleted`)
					this.$store.commit('accountListsDelete', this.editingList)
					this.modalList.hide()
				}, error => {
					Vue.toasted.show(`Error deleting list: ${error}`, { type: 'error' })
				})
			}
		},
		relativeDays(days) {
			const rtf = new Intl.RelativeTimeFormat('en')

			if (days > 350 || days < -350) {
				return rtf.format(Math.round(days / 365), 'year')
			} else if (days > 28 || days < -28) {
				return rtf.format(Math.round(days / 30.4), 'month')
			} else {
				return rtf.format(days, 'day')
			}
		},
	},
	computed: {
		...mapGetters([ 'auth', 'isStaff' ]),
		...mapState([ 'account', 'domainsMonitoring', 'accountLists', 'publicLists', 'badgeAvailability' ]),
		loadDomainsDebounced() {
			return debounce(this.loadDomains, 200)
		},
		accountListSelected() {
			return this.accountLists.find(l => this.params.list === l.id)
		},
	},
	watch: {
		params: {
			deep: true,
			handler() {
				this.domains = []
				this.pag.page = 1
				this.loadDomainsDebounced()
			}
		},
		$route() {
			this.params.list = this.$route.query.list || ''
			this.params.monitoring = this.$route.query.monitoring == 1 || this.$route.query.monitoring == 'true'
		},
		'pag.sortBy'() {
			this.pag.sortOrder = ['last_whois_check', 'monitoring'].includes(this.pag.sortBy) ? 'desc' : 'asc'

			this.domains = []
			this.loadDomainsDebounced()
		},
	},
}
</script>
