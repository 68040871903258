<template>

	<span class="btn btn-sm" :class="{ 'btn-primary': !domainsMonitoring.includes(domain), 'btn-outline-primary': domainsMonitoring.includes(domain) }" @click="monitorDomain(domain)">{{ domainsMonitoring.includes(domain) ? 'Monitoring ⚙️' : '📸 Monitor domain' }}</span>

</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'DomainMonitorButton',
	props: ['domain'],
	data() {
		return { }
	},
	created() {
		this.$store.dispatch('domainsMonitoring')
		this.$store.dispatch('accountInfo')
	},
	computed: {
		...mapGetters([ 'auth' ]),
		...mapState([ 'account', 'domainsMonitoring', 'domainsMonitoringData', 'domainMonitoringSelected', '$modalPlan', '$modalMonitoring' ]),
	},
	methods: {
		monitorDomain(domain) {
			this.$store.commit('selectMonitoredDomain', this.domainsMonitoringData.find(d => d.domain === this.domain))

			if (!this.auth || (this.account.domains >= this.account.domains_limit && !this.domainsMonitoring.includes(domain))) {
				this.$modalPlan.show()
				this.$store.dispatch('ev', { event: 'Plans popup', data: { domain, feature: 'Monitoring' } })
			} else if (this.domainsMonitoring.includes(this.domain)) {
				this.$modalMonitoring.show()
				this.$store.dispatch('ev', { event: 'Domain monitor settings', data: { domain, feature: 'Monitoring' } })
			} else {
				this.toggleDomainMonitor().then(() => {
					if (this.account.domains < 10) {
						this.$store.commit('selectMonitoredDomain', this.domainsMonitoringData.find(d => d.domain === this.domain))
						this.$modalMonitoring.show()
					}
				})
				this.$store.dispatch('ev', { event: this.domainsMonitoring.includes(this.domain) ? 'Stop domain monitor' : 'Start domain monitor', data: { domain, feature: 'Monitoring' } })
			}
		},
		toggleDomainMonitor() {
			return this.$store.dispatch('toggleDomainMonitor', this.domain)
		}
	},
	watch: {
		
	},
}
</script>
