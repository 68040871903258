<template>
	<div class="page-lists">

		<section class="page-head bg-transparent">

		</section>

		<section class="container-fluid py-3">

			<div v-if="auth">
				<h2 class="my-3">Your domain collections</h2>

				<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
					<div v-for="list in accountLists" :key="`list-${list.id}`" class="col my-3">
						<list-card :list="list" />
					</div>
				</div>
			</div>

			<h2 class="my-3">Collections of domains</h2>

			<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
				<div v-for="list in lists" :key="`list-${list.id}`" class="col my-3">
					<list-card :list="list" />
				</div>
			</div>

		</section>

	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

import domainsApi from '@/api.js'

import ListCard from '@/components/ListCard.vue'

export default {
	name: 'Lists',
	metaInfo: {
      title: 'Domain collections'
    },
	components: { ListCard },
	data() {
		return {
			states: {
				lists: 'loading',
			},

			lists: [],
		}
	},
	computed: {
		...mapGetters([ 'auth', 'isStaff' ]),
		...mapState([ 'account', 'domainsMonitoring', 'accountLists' ]),
	},
	created() {
		this.loadLists()
		this.$store.dispatch('accountInfo')
		this.$store.dispatch('domainsMonitoring')

		this.$store.dispatch('ev', { event: 'View Collections', data: { feature: 'Collections' } })
	},
	methods: {
		loadLists() {
			this.states.lists = 'loading'

			domainsApi.get(`lists?expand=person&orderBy=domains_count&order=desc`).then(({ data }) => {
				this.lists.push(...data)
				this.states.lists = 'loaded'
			}, error => {
				this.states.lists = 'error'
				Vue.toasted.error(`Error loading lists (${error})`)
			})
		},
	},
}
</script>
