<template>
  <div id="app">

    <nav class="navbar navbar-expand-lg fixed-top">
      <div class="container-fluid">

        <!--<router-link v-if="$route.meta.backTo" :to="$route.meta.backTo" class="order-1 order-lg-2 py-1 px-3">←</router-link>-->
        <button v-if="$route.meta.backTo" class="btn btn-sm fw-bold order-1 order-lg-2 d-lg-none" type="button" @click="$router.push(typeof $route.meta.backTo === 'function' ? $route.meta.backTo($route) : $route.meta.backTo)">←</button>
        <button v-else class="navbar-toggler order-1 order-lg-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">☰</button>

        <router-link to="/" class="navbar-brand font-heading d-none d-lg-flex order-2 order-lg-1">Domains App</router-link>
        <div class="d-lg-none order-2 order-lg-1 ps-2 pe-3" style="flex: 1">
          <search :query="$route.params.domain || $route.query.q || ''" class="search-mobile" placeholder="Search for any domain or keyword"></search>
        </div>

        <div class="offcanvas offcanvas-start order-5 order-lg-2" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
              <router-link to="/">Domains App</router-link>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav me-4">
              <li class="nav-item me-1 d-lg-none">
                <router-link to="/" class="nav-link">Homepage</router-link>
              </li>
              <li class="nav-item me-1">
                <router-link to="/domains" class="nav-link">Domains</router-link>
              </li>
              <li class="nav-item me-1">
                <router-link to="/collections" class="nav-link">Collections</router-link>
              </li>
              <li class="nav-item me-1">
                <router-link to="/tlds" class="nav-link">TLDs</router-link>
              </li>
            </ul>

            <div class="d-none d-lg-flex" style="flex: 1">
              <search :query="$route.params.domain || $route.query.q || ''" class="search-not-mobile" :focusable="true" placeholder="🔍 Search for any domain, keyword or .tld"></search>
            </div>

            <ul v-if="auth" class="navbar-nav d-none d-lg-flex ms-4">
              <li class="nav-item me-1">
                <router-link to="/domains-list?monitoring=1" class="nav-link">📸 Watchlist <span class="badge rounded-pill bg-primary">{{ domainsMonitoring.length }}</span></router-link>
              </li>

              <li class="nav-item dropdown ms-2">
                <span class="nav-link dropdown-toggle active" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  <img class="rounded-circle me-1" :src="`https://secure.gravatar.com/avatar/${md5(auth.email)}?s=50&d=mm`" :alt="auth.name" width="19" height="19" />
                  {{ auth.name }}
                </span>
                <div class="dropdown-menu dropdown-menu-end">
                  <router-link v-if="account" to="/domains-database?monitoring=1" class="dropdown-item" style="min-width: 200px;">
                    <div class="row align-items-center mb-2 gx-1">
                      <div class="col">
                        <small>Monitor domains</small>
                      </div>
                      <div class="col-auto">
                        <small class="text-muted">{{ domainsMonitoring.length }}/{{ account.domains_limit }}</small>
                      </div>
                    </div>
                    <div class="progress mb-2 bg-primary-lighter" style="height: 7px">
                      <div class="progress-bar" role="progressbar" :style="{width: `${domainsMonitoring.length / account.domains_limit * 100}%`}"></div>
                    </div>
                  </router-link>
                  <router-link class="dropdown-item" to="/account">Account</router-link>
                  <router-link class="dropdown-item" to="/account/plan-billing">Plan &amp; Billing</router-link>
                  <router-link class="dropdown-item" to="/account/api-keys">API</router-link>
                  <router-link v-if="isStaff" class="dropdown-item" to="/dash">☢️ Dashboard</router-link>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item" @click="$store.dispatch('authLogout')">Logout</button>
                </div>
              </li>
            </ul>
            <ul v-else class="navbar-nav ms-auto">
              <li v-if="!auth" class="nav-item me-1">
                <router-link to="/features" class="nav-link">Features</router-link>
              </li>
              <li v-if="!auth" class="nav-item me-1">
                <router-link to="/plans" class="nav-link">Plans &amp; Pricing</router-link>
              </li>
              <li class="nav-item ms-4">
                <router-link to="/account/sign-in" class="nav-link">Sign in</router-link>
              </li>
              <li class="nav-item ms-2"><router-link to="/account/register" class="nav-link btn btn-primary text-light">Create account</router-link></li>
            </ul>
          </div>
        </div>

        <router-link to="/account" class="order-4 d-lg-none">
          <img v-if="auth" class="rounded-circle me-1" :src="`https://secure.gravatar.com/avatar/${md5(auth.email)}?s=50&d=mm`" :alt="auth.name" width="19" height="19" />
          <span v-else>👤</span>
        </router-link>
      </div>

    </nav>

    <div id="content">
      <router-view/>
    </div>

    <footer class="site-footer py-3">
      <div class="container">
        <div class="row">
          <div class="col-md-8 my-2 text-center text-md-start">
            <router-link to="/" class="mx-2">Home</router-link>
            <router-link to="/about" class="mx-2">About</router-link>
            <router-link to="/domains" class="mx-2">Domains</router-link>
            <router-link to="/tlds" class="mx-2">TLDs</router-link>
            <router-link to="/browser-extension" class="mx-2 d-inline-block">Browser extension</router-link>

            <a href="https://twitter.com/DomainsApp" class="mx-2" target="_blank">
              <img src="@/assets/icon-twitter.svg" height="24" alt="Domains App on Twitter" />
            </a>
            <a href="https://github.com/LayeredStudio?q=domains" class="mx-2" target="_blank">
              <img src="@/assets/icon-github.svg" height="24" alt="Domains App on GitHub" />
            </a>
          </div>
          <div class="col-md-4 text-center text-md-end my-2">
            <a href="https://domains.checkly-dashboards.com/" class="mx-2" target="_blank">System Status</a>
            <router-link to="/policies" class="mx-2">Privacy &amp; Terms</router-link>
          </div>
        </div>
      </div>
    </footer>


      <!-- Modal for plan upgrade -->
      <div class="modal fade" id="modal-plan" tabindex="-1" role="dialog" aria-labelledby="modal-plan-label" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content border-0 shadow-sm">
            <div class="modal-header">
              <h5 class="modal-title" id="modal-plan-label">{{ !account ? 'Account needed' : 'Monitored domains limit reached' }}</h5>
              <button type="button" class="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="!account" class="modal-body">
              <p>Create a FREE account to start monitoring this domain.</p>
              <p>Check out how <router-link to="/features">monitoring domains can help</router-link>.</p>
            </div>
            <div v-else class="modal-body">
              <p>Looks like you reached the limit of monitoring domains for your plan.</p>
              <p>Please remove other domains from monitoring or upgrade your plan.</p>
            </div>
            <div class="modal-footer">
              <router-link v-if="!auth" :to="`/account/sign-in?redirect=${encodeURIComponent('/domains?q=' + ($route.query.q || $route.params.domain))}`" rel="nofollow" class="btn btn-sm btn-outline-primary me-auto">Sign in</router-link>

              <router-link v-if="auth" to="/plans" class="btn btn-sm btn-primary">{{ 'See plans & upgrade' }} →</router-link>
              <router-link v-else :to="`/account/register?redirect=${encodeURIComponent('/domains?q=' + ($route.query.q || $route.params.domain))}`" rel="nofollow" class="btn btn-sm btn-primary">{{ 'Create account' }} →</router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for monitoring settings -->
      <div class="modal fade" id="modal-monitoring" tabindex="-1" role="dialog" aria-labelledby="modal-monitoring-label" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div v-if="auth && domainMonitoringSelected" class="modal-content border-0 shadow-sm">
            <div class="modal-header">
              <h5 class="modal-title" id="modal-monitoring-label">Monitoring options for "{{ domainMonitoringSelected.domain }}"</h5>
              <button type="button" class="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form v-if="domainMonitoringSelected">

                <p class="lead">This domain is being watched constantly, and we'll notify you for these changes:</p>

                <div class="form-group row mb-3">
                  <label class="col-sm-3 col-form-label">Monitor:</label>
                  <div class="col-sm-9">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="domainMonitoringSelected.types" value="whois" id="monitor-whois">
                      <label class="form-check-label" for="monitor-whois"><strong>WHOIS</strong> - For domain availability, owner or transfers</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="domainMonitoringSelected.types" value="dns" id="monitor-dns">
                      <label class="form-check-label" for="monitor-dns"><strong>DNS Records</strong> - For subdomains or email settings</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="domainMonitoringSelected.types" value="sale" id="monitor-sale">
                      <label class="form-check-label" for="monitor-sale"><strong>Sales</strong> - If the domain is sold in a marketplace</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="domainMonitoringSelected.types" value="note" id="monitor-note">
                      <label class="form-check-label" for="monitor-note"><strong>Notes</strong> - Interesting articles or mentions about this domain</label>
                    </div>
                  </div>
                </div>
                <div v-if="domainMonitoringSelected.types.includes('whois')" class="form-group row mb-3">
                  <label class="col-sm-3 col-form-label">Notify for:</label>
                  <div class="col-sm-9">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="domainMonitoringSelected.whois_priority" value="normal" id="whois-priority-normal">
                      <label class="form-check-label" for="whois-priority-normal"><strong>All changes</strong></label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" v-model="domainMonitoringSelected.whois_priority" value="high" id="whois-priority-high">
                      <label class="form-check-label" for="whois-priority-high"><strong>Important changes</strong> - ex: domain expiration, renewal, sale or transfer</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button class="btn btn-sm text-danger me-auto" @click="$store.dispatch('toggleDomainMonitor', domainMonitoringSelected.domain)" data-bs-dismiss="modal">Stop monitoring domain</button>
              <button class="btn btn-sm btn-primary" @click="$store.dispatch('updateSelectedDomainMonitor')" data-bs-dismiss="modal">Save</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="modal-plans" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Discover related domains</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p class="lead">With our <router-link to="/plans">Pro plan</router-link>, you can see a list of domains related to this one.</p>
              <p class="mb-2"><strong>By Registrant</strong> - List of domains registered by the same person or company.</p>
              <p class="mb-2"><strong>By Name Servers</strong> - Sometimes it's useful info to see all domains using the same Name Servers. For some, we can determine by the NS that they belong to same owner (look for the sign <span class="badge bg-info-lighter text-info">👋 Same owner</span>).</p>
              <p><strong>By Registrar</strong> - See a list of domains registered at same registrar.</p>
            </div>
            <div v-if="!isPro" class="modal-footer">
              <router-link v-if="!auth" :to="`/account/sign-in?redirect=${encodeURIComponent('/domains?q=' + ($route.query.q || $route.params.domain))}`" rel="nofollow" class="btn btn-sm btn-outline-primary me-auto">Sign in</router-link>

              <router-link v-if="auth" to="/plans" class="btn btn-sm btn-primary">See plans & upgrade →</router-link>
              <router-link v-else :to="`/account/register?redirect=${encodeURIComponent('/domains?q=' + ($route.query.q || $route.params.domain))}`" rel="nofollow" class="btn btn-sm btn-primary">{{ 'Create account' }} →</router-link>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<style lang="scss">
$offcanvas-vertical-height: 70vh;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap');
@import '~@layered/layered-design';

.bg-cyan-50 {
  background-color: #cffafe;
}

@keyframes gradientAnim {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

h1, h2, h3, h4, h5, h6, .font-heading {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.01em;
}

.text-gradient {
    -webkit-print-color-adjust: exact;
    background-image: linear-gradient(90deg, rgb(247, 102, 152), rgb(192, 63, 162) 60%, rgb(140, 27, 171) 100%);
    background-size: cover;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-highlight {
  background: linear-gradient(90deg, #fee5da, #fff5ca);
  background-size: 400% 400%;
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 0.25rem;
  animation: gradientAnim 6s ease infinite;
}

h1, h2, h3, h4 {
  .text-highlight {
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 0.3rem;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

#app {
  color: #2c3e50;
}

// Make table expand to edges of card
.card-table {
  margin-left: -1rem;
  margin-right: -1rem;

  .table {
    margin-bottom: 0;
  }

  .table tr td,
  .table tr th {
    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1rem;
    }
  }
}

.text-gray {
  color: #6f7479;
}

.table td {
  padding: 0.5rem;
}

.table thead th {
  border-bottom: 1px solid var(--bs-info-light) !important;
}

.badge-sm {
  padding: 0.25em;
  font-size: 70%;
}

.bg-dark-transparent {
  background: rgba(0, 0, 0, .1);
}

.pro-box {
  background-color: #fdf4ff;
  border: 1px solid #fae8ff;

  &:hover {
    border-color: #f5d0fe;
  }

  a {
    border-radius: 0.5rem;

    &:hover {
      background-color: #fae8ff;
    }

    &.disabled {
      pointer-events: none;
      color: #777;
    }
  }

  button {
    border-radius: 0.5rem;
    border: 0;
    background-color: transparent;

    &:hover {
      background-color: #fae8ff;
    }

    &.disabled {
      pointer-events: none;
      color: #777;
    }
  }

  .is-pro {
    filter: blur(6px);
  }
}

.nicer-list {
  li {
    margin-bottom: 0.5rem;
  }
}

.domain-tabs-scroll {
  margin-left: -1rem;
  margin-right: -1rem;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

.navbar-brand {
  color: #000;
  font-weight: 700;
}

.navbar-nav {
  .nav-link.router-link-active {
    color: $gray-900;
    background-color: $gray-200;
    border-radius: $border-radius;
    padding-left: 0.5rem;
  }
}

// TODO add variable in @layered/layered-design
.list-group-item.active {
  background-color: $gray-200;
  color: $dark;
  border-color: $primary-lighter;
  border-left: 3px solid $primary;
}

.bg-diff-line-new {
  background-color: #e6ffed;
}

.bg-diff-line-deleted {
  background-color: #ffeef0;
}

.bg-diff-new {
  background-color: #acf2bd;
}

.bg-diff-deleted {
  background-color: #fdb8c0;
}

@include media-breakpoint-down(md) {
  #offcanvasNavbar {
    min-height: 350px;
  }
}

.link-to-related {
  display: inline-block;
  background-color: #f8fafc;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 10px 0 0;
  border-radius: 15px;
  color: #334155;
  margin-right: 15px;

  .link-icon {
    display: inline-block;
    background-color: #f1f5f9;
    padding: 3px 5px;
    border-radius: 15px;
    min-width: 30px;
    text-align: center;
    margin-right: 3px;
  }

  &.color-sky {
    background-color: #f0f9ff;

    .link-icon {
      background-color: #e0f2fe;
    }
  }

  &.color-purple {
    background-color: #faf5ff;

    .link-icon {
      background-color: #f3e8ff;
    }
  }

  &.color-indigo {
    background-color: #eef2ff;

    .link-icon {
      background-color: #e0e7ff;
    }
  }

  &.color-violet {
    background-color: #f5f3ff;

    .link-icon {
      background-color: #ede9fe;
    }
  }
}
</style>


<script>
import { mapGetters, mapState } from 'vuex'
//import 'bootstrap'
import md5 from 'md5'

import Search from '@/components/Search.vue'
import { tracker } from '@/utils.js'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Search and monitor domains',
    // all titles will be injected into this template
    titleTemplate: '%s | Domains App'
  },
  components: {
    Search
  },
  computed: {
    ...mapGetters([ 'auth', 'isStaff', 'isPro' ]),
    ...mapState([ 'account', 'domainMonitoringSelected', 'domainsMonitoring' ]),
  },
  created() {
    this.$store.dispatch('loadPublicLists')
    this.$store.dispatch('accountLists')
    this.$store.dispatch('domainsMonitoring')

    if (!this.auth?.email?.endsWith('@laye.red') && !localStorage.getItem('plausible_ignore') && Math.floor(Math.random() * 3)) {
      tracker.start()

      if (this.auth) {
        tracker.setUserID(this.auth.email)
      }
    }
  },
  mounted() {
    this.$store.commit('prepareModals')
  },
  methods: {
    md5,
    hasHistory() {
      return history.length
    }
  },
}
</script>

