<template>
	<div class="domains">

		<section class="container-fluid py-6">

			<h4 class="mb-3">Domains database</h4>

			<div class="card">
				<div class="card-body" style="min-height: 450px">
					<div class="row-filters row align-items-center mb-2">
						<div class="col-6 col-md-4 col-lg-3 mb-2">
							<label for="filter-monitoring" class="d-block rounded p-1 cursor-pointers" :class="filters.monitoring ? 'bg-primary-lighter' : 'bg-light'">
								<div class="form-check form-switch">
									<input type="checkbox" class="form-check-input" v-model="filters.monitoring" id="filter-monitoring" />
									<label class="form-check-label" for="filter-monitoring">Domains I monitor</label>
								</div>
							</label>
						</div>

						<div class="col-6 col-md-4 col-lg-3 mb-2">
							<div class="dropdown dropdown-tlds" :class="{'dropdown-empty': !filters.tlds.length}">
								<button class="btn w-100 text-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">
									{{ filters.tlds.length ? `TLD: ${filters.tlds.join(', ')}` : '➕ TLD' }}
								</button>
								<ul class="dropdown-menu">
									<li v-for="tld in tlds" :key="tld">
										<label class="dropdown-item">
											<input type="checkbox" v-model="filters.tlds" :value="tld" /> .{{ tld }}
										</label>
									</li>
								</ul>
							</div>
						</div>

						<div class="col-6 col-md-4 col-lg-2 mb-2">
							<select class="form-select form-select-sm" :class="{'has-value': filters.availability}" v-model="filters.availability">
								<option :value="null">{{ filters.availability ? 'All' : '➕ Availability' }}</option>
								<option value="available">Available</option>
								<option value="registered">Registered</option>
								<option value="for-sale">For sale</option>
							</select>
						</div>

						<div class="col-6 col-md-4 col-lg-2 mb-2">
							<select class="form-select form-select-sm" :class="{'has-value': filters.list}" v-model="filters.list">
								<option :value="null">{{ filters.list ? 'No collection' : '➕ Collection' }}</option>
								<optgroup v-if="auth" label="Your collections">
									<option v-for="list in accountLists" :key="list.id" :value="list.id">{{ list.name }} ({{ list.domains_count }})</option>
								</optgroup>
								<optgroup label="Featured collections">
									<option v-for="list in publicLists" :key="list.id" :value="list.id">{{ list.name }} ({{ list.domains_count }})</option>
								</optgroup>
							</select>
						</div>

						<div v-if="filters.availability !== 'available'" class="col-6 col-md-4 col-lg-2 mb-2">
							<select class="form-select form-select-sm" :class="{'has-value': filters.status}" v-model="filters.status">
								<option :value="null">{{ filters.status ? 'All' : '➕ Domain status' }}</option>
								<option value="pendingDelete">Pending delete</option>
								<option value="pendingTransfer">Pending transfer</option>
								<option value="redemptionPeriod">Redemption period</option>
							</select>
						</div>

						<div v-if="filters.availability !== 'available'" class="col-6 col-md-4 col-lg-3 mb-2">
							<button class="w-100 text-start" :class="{'btn-empty': !hasRegistrantFilter}" @click="setEditFilters(['registrant_name', 'registrant_organization', 'registrant_city', 'registrant_state', 'registrant_country'])" data-bs-toggle="modal" data-bs-target="#modal-registrant">
								{{ hasRegistrantFilter ? `Registrant: ${[filters.registrant_name, filters.registrant_organization, filters.registrant_city, filters.registrant_state, filters.registrant_country].filter(Boolean).join(', ')}` : '➕ Registrant' }}
							</button>
						</div>
					</div>

					<loader v-if="state === 'loading'" class="my-6" />

					<div v-else-if="state === 'idle' && !domains.length" class="text-center py-5">
						<img src="/images/plan-pro.png" alt="No domains found" height="150" class="mb-3" />

						<p class="lead">Oops, couldn't find any domain. Try changing the filters</p>
					</div>

					<div v-else-if="state === 'idle'" class="card-table">
						<div class="row align-items-center mb-2 mx-2">
							<div class="col-6">
								<p class="mb-0">
									<small v-if="state === 'idle' && pag.total > 35000">Found <strong>many domains</strong>. Try some filters 👆</small>
									<small v-else-if="state === 'idle'">Found <strong>{{ pag.total }} {{ ['available', 'registered'].includes(filters.availability) ? filters.availability : '' }} domains</strong></small>
								</p>
							</div>
							<div class="col-6 text-end">
								<small class="d-none d-md-inline-block text-muted me-1">Order by</small>
								<select class="form-control form-control-sm d-inline-block w-auto" v-model="pag.sortBy">
									<option value="domain">Alphabetically</option>
									<option value="length">Keyword length</option>
									<option value="date_expiry">Expires soon</option>
									<option v-if="isStaff" value="monitoring">Follows</option>
								</select>
							</div>
						</div>

						<table class="table table-sm table-hover">
							<thead>
								<tr>
									<th>
										Domain <small v-if="pag.sortBy === 'domain'">{{ pag.sortOrder === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th>Availability</th>
									<th class="hover cursor-pointer" @click="orderBy('date_expiry', 'asc')">
										Expires on <small v-if="pag.sortBy === 'date_expiry'">{{ pag.sortOrder === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th>Registrant</th>
									<th>Registrar</th>
									<th class="hover cursor-pointer" @click="orderBy('activity_count', 'desc')">
										Changes <small v-if="pag.sortBy === 'activity_count'">{{ pag.sortOrder === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th v-if="isStaff" class="hover cursor-pointer" @click="orderBy('similar_web_rank', 'asc')">
										Similarweb <small v-if="pag.sortBy === 'similar_web_rank'">{{ pag.sortOrder === 'asc' ? '▲' : '▼' }}</small>
									</th>
									<th>
										<small v-if="pag.sortBy === 'monitoring'">{{ pag.sortOrder === 'asc' ? '▲' : '▼' }}</small>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="domain in domains" :key="domain.id">
									<td>
										<router-link :to="`/${domain.domain}`" class="text-dark" style="font-size: 1.5rem">{{ domain.domain }}</router-link>
										<span v-if="isStaff" class="ms-1">
											{{ '📸'.repeat(domain.monitoring / 5 - (filters.monitoring ? 1 : 0)) }}
											{{ '🗂️'.repeat(domain.listed) }}
										</span>
									</td>
									<td>
										<span class="badge text-capitalize me-1" :class="[badgeAvailability[domain.availability]]">{{ labelAvailability[domain.availability] || domain.availability }}</span>
										<span v-if="domain.availability === 'registered' && domain.services.buy.length" class="badge border border-primary text-primary">🛒 For sale</span>

										<p v-for="status in domain.status.filter(s => !s.startsWith('client') && !['serverdeleteprohibited', 'servertransferprohibited', 'serverupdateprohibited', 'ok', 'registered'].includes(s.toLowerCase()))" :key="`${domain.domain}-${status}`" class="mb-0">
											<span class="badge badge-sm bg-secondary-light text-secondary" :class="{'bg-red-100 text-red-500': status.toLowerCase() === 'pendingdelete'}">{{ status }}</span>
										</p>
									</td>
									<td>
										<p v-if="domain.date_expiry" class="mb-1">{{ (new Date(domain.date_expiry)).toLocaleDateString() }}</p>
										<small v-if="domain.date_expiry" :class="{'text-muted': domain.expiryDays > 0, 'text-warning': domain.expiryDays <= 0}">{{ relativeDays(domain.expiryDays) }}</small>
									</td>
									<td>
										<p v-if="domain.availability === 'registered'" class="mb-1 text-break">👤 <span v-html="uniqueValues([domain.registrant.name, domain.registrant.organization], 'Anonymous owner')"></span></p>
										<p v-if="domain.availability === 'registered'" class="mb-0 text-break">📍 <span v-html="uniqueValues([domain.registrant.city, domain.registrant.stateOrProvince, domain.registrant.country], 'No location found')"></span></p>
									</td>
									<td>
										{{ domain.registrar.name }}
									</td>
									<td>
										<router-link v-if="domain.activity_count" :to="`/${domain.domain}/history`" class="fw-bolder">{{ domain.activity_count }}</router-link>
										<small v-else class="text-muted">0</small>
									</td>
									<td>
										<a v-if="domain.similar_web_rank" :href="`https://www.similarweb.com/website/${domain.domain}/`" target="_blank">{{ domain.similar_web_rank.toLocaleString() }}</a>
										<small v-else class="text-muted">-</small>
									</td>
									<td>
										<span class="btn btn-sm me-1 mt-1" :class="{ 'btn-primary': !domainsMonitoring.includes(domain.domain), 'btn-outline-primary': domainsMonitoring.includes(domain.domain) }" @click="monitorDomain(domain.domain)">{{ domainsMonitoring.includes(domain.domain) ? 'Monitoring' : '📸 Monitor' }}</span>

										<div v-if="isStaff" class="dropdown mt-1">
											<button class="btn btn-sm btn-outline-danger dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">⠇</button>
											<div class="dropdown-menu dropdown-menu-right">
												<button class="dropdown-item text-warning" @click="deleteDomainWhois($event, domain)">Delete WHOIS</button>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
			</div>

		</section>

		<!-- Modal for plan upgrade -->
		<div class="modal fade" id="modal-registrant" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content border-0 shadow-sm">
					<div class="modal-header">
						<h5 class="modal-title">Registrant filter</h5>
						<button type="button" class="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="form-group mb-3">
							<label class="form-label" for="registrant-name">Name</label>

							<input type="text" id="registrant-name" class="form-control" v-model="filtersEdit.registrant_name" placeholder="Search by registrant name">
						</div>

						<div class="form-group mb-3">
							<label class="form-label" for="registrant-org">Organization</label>

							<input type="text" id="registrant-org" class="form-control" v-model="filtersEdit.registrant_organization" placeholder="Search by registrant organization">
						</div>

						<div class="row gx-3">
							<div class="col">
								<div class="form-group mb-3">
									<label class="form-label" for="registrant-city">City</label>

									<input type="text" id="registrant-city" class="form-control" v-model="filtersEdit.registrant_city" placeholder="London, Boise, etc">
								</div>
							</div>
							<div class="col">
								<div class="form-group mb-3">
									<label class="form-label" for="registrant-state">State or Province</label>

									<input type="text" id="registrant-state" class="form-control" v-model="filtersEdit.registrant_state" placeholder="CA, IL, Ontario">
								</div>
							</div>
							<div class="col-2">
								<div class="form-group mb-3">
									<label class="form-label" for="registrant-country">Country</label>

									<input type="text" id="registrant-country" class="form-control" v-model="filtersEdit.registrant_country" maxlength="2" placeholder="US, ES">
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer justify-content-between">
						<button class="btn btn-sm text-dark" data-bs-dismiss="modal" @click="resetRegistrantFilter">Reset registrant</button>

						<button class="btn btn-sm btn-primary px-5" data-bs-dismiss="modal" @click="setFilters(['registrant_name', 'registrant_organization', 'registrant_city', 'registrant_state', 'registrant_country'])">Search</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<style lang="scss" scoped>
.row-filters {
	button {
		border: 1px solid #c4b5fd;
		border-radius: 0.2rem;
		background-color: #f4f8fb;
		color: #212529;
	}

	.form-select,
	.form-control {
		border-color: #c4b5fd;
	}

	button.btn-empty,
	.dropdown-empty button,
	.form-select:not(.has-value),
	.form-control:placeholder-shown {
		border-style: dashed;
		border-color: #cbd5e1;
		color: #666;
	}
}


</style>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { debounce } from 'vue-debounce'
//import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import domainsApi from '@/api.js'

import Loader from '@/components/Loader.vue'

export default {
	name: 'TLDs',
	components: { Loader },
	data() {
		return {
			labelAvailability: {
				registered: 'taken',
			},
			tlds: ['com', 'net', 'org', 'co', 'io', 'app', 'dev', 'info', 'link', 'me', 'xyz', 'uk', 'co.uk', 'de', 'ro', 'us'],
			statuses: ['pendingDelete', 'pendingTransfer', 'redemptionPeriod'],
			state: 'idle',
			domainsReqController: null,
			domains: [],
			pag: {
				page: 1,
				total: 0,
				sortBy: localStorage.getItem('domains-list-sort-by') || 'domain',
				sortOrder: localStorage.getItem('domains-list-sort-order') || 'asc',
			},
			filters: {
				monitoring: this.$route.query.monitoring == 1 || this.$route.query.monitoring == 'true',
				tlds: (this.$route.query.tlds || '').split(',').filter(Boolean),
				availability: this.$route.query.availability || null,
				list: this.$route.query.list || null,
				status: this.$route.query.status || null,
				registrant: this.$route.query.registrant || '',
				registrant_name: this.$route.query.registrant_name || '',
				registrant_organization: this.$route.query.registrant_organization || '',
				registrant_city: this.$route.query.registrant_city || '',
				registrant_state: this.$route.query.registrant_state || '',
				registrant_country: this.$route.query.registrant_country || '',
				registrar: this.$route.query.registrar || '',
				ns: this.$route.query.ns || '',
				//length: [1, 11],
			},
			filtersEdit: {
				registrant_name: '',
				registrant_organization: '',
				registrant_city: '',
				registrant_state: '',
				registrant_country: '',
			}
		}
	},
	created() {
		this.$store.dispatch('ev', { event: 'View Domains Database' })
		//console.log('TLDs', 'created')
		//this.query = this.$route.query.d

		this.$store.dispatch('accountInfo')
		this.$store.dispatch('domainsMonitoring')
		this.loadDomains()
	},
	methods: {
		alert,
		uniqueValues(values, defaultValue) {
			if (defaultValue) {
				defaultValue = `<small><i class="text-muted">${defaultValue}</i></small>`
			}
			return [...new Set(values)].filter(Boolean).join(', ') || defaultValue
		},
		loadDomains() {
			this.state = 'loading'
			const params = {}

			// add filters
			for (const label in this.filters) {
				if (Array.isArray(this.filters[label]) && this.filters[label].length) {
					params[label] = this.filters[label].join(',')
				} else if (this.filters[label]) {
					params[label] = this.filters[label]
				}
			}

			// set filters as url params
			this.$router.replace({ name: 'DomainsDatabase', query: params }).catch(() => {})

			// add sorting
			params.sortBy = this.pag.sortBy
			params.sortOrder = this.pag.sortOrder

			// stop running requests
			this.domainsReqController?.abort()
			this.domainsReqController = new AbortController()

			domainsApi.get(`domains`, {
				params,
				signal: this.domainsReqController.signal,
			}).then(({ data, headers }) => {
				this.pag.total = parseInt(headers['x-total'], 10)

				this.domains.push(...data)
				this.state = 'idle'
			}, error => {
				if (error.code !== 'ERR_CANCELED') {
					this.state = 'error'
					Vue.toasted.error(`Error loading domains: ${error.message}`)
				}
			})
		},
		monitorDomain(domain) {
			if (!this.auth || (this.account.domains >= this.account.domains_limit && !this.domainsMonitoring.includes(domain))) {
				alert('plan upgrade needed')
			} else {
				this.$store.dispatch('toggleDomainMonitor', domain)
			}
		},
		setFilters(filters) {
			filters.forEach(filter => {
				this.filters[filter] = this.filtersEdit[filter]
			})
		},
		setEditFilters(filters) {
			filters.forEach(filter => {
				this.filtersEdit[filter] = this.filters[filter]
			})
		},
		resetRegistrantFilter() {
			this.filters.registrant_name = ''
			this.filters.registrant_organization = ''
			this.filters.registrant_city = ''
			this.filters.registrant_state = ''
			this.filters.registrant_country = ''
		},

		relativeDays(days) {
			const rtf = new Intl.RelativeTimeFormat('en')

			if (days > 350 || days < -350) {
				return rtf.format(Math.round(days / 365), 'year')
			} else if (days > 28 || days < -28) {
				return rtf.format(Math.round(days / 30.4), 'month')
			} else {
				return rtf.format(days, 'day')
			}
		},
		orderBy(field, defaultOrder) {
			this.pag.sortOrder = this.pag.sortBy == field ? (this.pag.sortOrder === 'asc' ? 'desc' : 'asc') : defaultOrder
			this.pag.sortBy = field
		},

		deleteDomainWhois($event, domain) {
			$event.target.disabled = true

			domainsApi(`domain/${domain.domain}/delete-whois`).then(({ data }) => {
				Vue.toasted.show(`Deleted ${data.deleted}/${data.found} WHOIS (in ${data.time}s)`)
			})
		}
	},
	computed: {
		...mapGetters([ 'auth', 'isStaff' ]),
		...mapState([ 'account', 'domainsMonitoring', 'accountLists', 'publicLists', 'badgeAvailability' ]),
		loadDomainsDebounced() {
			return debounce(this.loadDomains, 300)
		},
		hasRegistrantFilter() {
			return this.filters.registrant_name || this.filters.registrant_organization || this.filters.registrant_city || this.filters.registrant_state || this.filters.registrant_country
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.state = 'loading'
				this.domains = []
				this.pag.page = 1
				this.loadDomainsDebounced()
			}
		},
		'pag.sortBy'() {
			localStorage.setItem('domains-list-sort-by', this.pag.sortBy)
			localStorage.setItem('domains-list-sort-order', this.pag.sortOrder)

			this.state = 'loading'
			this.domains = []
			this.loadDomainsDebounced()
		},
	},
}
</script>
