<template>
	<div class="page-domains">

		<section v-if="$route.query.q" class="container py-5">

			<div class="row">

				<div class="section-tld-list col-md-4 mb-4 pt-4">

					<div v-if="tldList.length" class="bg-white rounded p-3 mb-3">

						<p class="lead mb-3">
							<strong>{{ tldList[0].keyword }}</strong>
							<!-- <button class="btn btn-sm" @click="textToSpeech(tldList[0].keyword)">🔉</button> -->
							<small class="label-keyword d-inline-block bg-cyan-100 px-1 rounded animate__animated animate__zoomIn ms-2" v-if="keyword && (keyword.is_word || keyword.is_brand || keyword.is_name || keyword.is_location)">
								{{ keywordDescription }}
							</small>
						</p>

						<p class="subtitle mt-2 mb-1 text-muted">Extensions stats:</p>
						<div v-if="keywordStats[tldList[0].keyword]" class="row align-items-center text-center">
							<div class="col">
								<div class="rounded-3 bg-success-lighter py-1">
									<span class="my-0 fs-4 text-success">{{ keywordStats[tldList[0].keyword].available.length  }}</span>
									<p class="text-uppercase text-dark mb-0"><small>Available</small></p>
								</div>
							</div>
							<div v-if="keywordStats[tldList[0].keyword].forSale.length" class="col">
								<div class="rounded-3 bg-primary-lighter py-1">
									<p class="mb-0 fs-4 text-primary">
										{{ keywordStats[tldList[0].keyword].forSale.length  }}
									</p>
									<p class="text-uppercase text-dark mb-0">For Sale</p>
								</div>
							</div>
							<div class="col">
								<div class="rounded-3 bg-info-lighter py-1">
									<p class="mb-0 fs-4 text-blue-500">
										{{ keywordStats[tldList[0].keyword].registered.length  }}
									</p>
									<p class="text-uppercase text-dark mb-0">Taken</p>
								</div>
							</div>
						</div>

						<div v-if="isStaff && keyword" class=" mt-3">
							<table class="table mb-2">
								<tbody>
									<tr>
										<td>Top 3 average</td>
										<td>{{ averageAge(tldList[0].keyword, ['com', 'net', 'org']) }} years</td>
									</tr>
									<tr>
										<td>Top 5 average</td>
										<td>{{ averageAge(tldList[0].keyword, ['com', 'net', 'org', 'co', 'app']) }} years</td>
									</tr>
									<tr>
										<td>Views / weekly</td>
										<td>{{ keyword.views }} / {{ keyword.views_weekly }}</td>
									</tr>
								</tbody>
							</table>

							<div class="form-group">
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="checkbox" id="inlineCheckbox1" v-model="keyword.is_word" value="1" @change="updateKeyword">
									<label class="form-check-label" for="inlineCheckbox1">Word</label>
								</div>
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="checkbox" id="inlineCheckbox2" v-model="keyword.is_brand" value="1" @change="updateKeyword">
									<label class="form-check-label" for="inlineCheckbox2">Brand</label>
								</div>
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="checkbox" id="inlineCheckbox3" v-model="keyword.is_name" value="1" @change="updateKeyword">
									<label class="form-check-label" for="inlineCheckbox3">Name</label>
								</div>
								<div class="form-check form-check-inline">
									<input class="form-check-input" type="checkbox" id="inlineCheckbox4" v-model="keyword.is_location" value="1" @change="updateKeyword">
									<label class="form-check-label" for="inlineCheckbox4">Loc</label>
								</div>
							</div>
						</div>
					</div>

					<ul class="list-group mb-2">
						<li v-for="tld in tldList" :key="tld.tld" class="list-group-item cursor-pointer px-3" :class="{active: tld.active}" @click="selectKeyword(tld.domain)">
							<span v-if="!domainData[tld.domain] || domainData[tld.domain] === 'loading'" class="badge text-dark float-end">..</span>
							<span v-else-if="isError(domainData[tld.domain])" class="badge bg-red-100 float-end">Error</span>
							<span v-else-if="domainData[tld.domain].availability === 'registered' && domainData[tld.domain].services.buy.length" class="badge float-end text-capitalize text-primary border border-primary">For Sale</span>
							<span v-else class="badge float-end text-capitalize" :class="[badgeAvailability[domainData[tld.domain].availability]]">{{ labelAvailability[domainData[tld.domain].availability] || domainData[tld.domain].availability }}</span>

							{{ tld.domain }}
							<span v-if="domainsMonitoring.includes(tld.domain)">📸</span>
							<span v-if="domainData[tld.domain] && domainData[tld.domain].availability && domainData[tld.domain].availability === 'registered'">
								<span v-if="domainData[tld.domain].status.includes('pendingDelete') || domainData[tld.domain].status.includes('PendingDelete')" class="badge bg-red-100 text-red-500 ms-1">Pending delete</span>
								<span v-else-if="Number.isInteger(domainData[tld.domain].dates.expiryDays) && domainData[tld.domain].dates.expiryDays < 30" class="ms-1">
									<span v-if="domainData[tld.domain].dates.expiryDays < 0" class="badge bg-orange-100 text-red-500">Expired</span>
									<span v-else class="badge bg-orange-100 text-orange-500">Expires soon</span>
								</span>
							</span>
						</li>
					</ul>

					<p><button class="btn btn-sm" data-bs-toggle="modal" data-bs-target="#modal-search-tlds" @click="showSearchTlds">⚙️ Customize TLDs</button></p>
				</div>

				<div class="col-md-8 col-domain-info pt-4">

					<div :class="[isMobile ? 'offcanvas offcanvas-bottom' : 'card']">
						<div :class="[isMobile ? 'offcanvas-header bg-light py-2' : 'card-header']">
							<div class="row align-items-center">
								<div class="col">
									<h1 class="mb-2 mb-lg-1" :class="[`display-${domain.length > 15 ? 6 : 5}`]">
										<router-link :to="`/${domain}`" class="text-dark">{{ domain }}</router-link>
									</h1>
								</div>
								<div class="col-lg-auto my-1">
									<div class="h-scrollz">
										<a v-if="domainData[domain] && domainData[domain].availability === 'registered'" :href="`http://${domain}`" class="btn btn-sm btn-outline-secondary me-2" target="_blank" rel="nofollow"><img :src="`https://logo.clearbit.com/${domain}?size=14`" @error="$event.target.src = 'https://dmns.app/images/domains-app-icon.png'" width="14" height="14" :alt="domain" /> Visit site</a>

										<domain-monitor-button :domain="domain" class="me-2"></domain-monitor-button>

										<domain-lists-button :domain="domainData[domain]" class="me-2"></domain-lists-button>

										<router-link :to="`/${domain}#add-note`" class="btn btn-sm btn-outline-warning my-1">Add note</router-link>
									</div>
								</div>
							</div>
						</div>

						<div :class="[isMobile ? 'offcanvas-header py-0 bg-light' : 'card-header py-0']">
							<nav class="overflow-auto domain-tabs-scroll">
								<ul class="nav nav-tabs">
									<li class="nav-item">
										<router-link :to="`/${domain}`" replace class="nav-link text-center active">
											Overview
										</router-link>
									</li>
									<li class="nav-item">
										<router-link :to="`/${domain}/whois`" replace class="nav-link text-center">
											WHOIS
										</router-link>
									</li>
									<li class="nav-item">
										<router-link :to="`/${domain}/name-servers`" replace class="nav-link text-center">
											Name Servers <span v-if="domainData[domain] && domainData[domain].ns.length" class="badge rounded-pill bg-secondary-light text-secondary">{{ domainData[domain].ns.length }}</span>
										</router-link>
									</li>
									<li class="nav-item">
										<router-link :to="`/${domain}/dns-records`" replace class="nav-link text-center">
											DNS Records <span v-if="domainDnsRecords[domain] && !isError(domainDnsRecords[domain])" class="badge rounded-pill bg-secondary-light text-secondary">{{ Object.values(domainDnsRecords[domain]).flat().length }}</span>
										</router-link>
									</li>
									<li class="nav-item">
										<router-link :to="`/${domain}/history`" replace class="nav-link text-center">
											History <span v-if="domainData[domain] && domainData[domain].activityCount" class="badge rounded-pill bg-blue-100 text-blue-500">{{ domainData[domain].activityCount }}</span>
										</router-link>
									</li>
									<li v-if="betas.includes('emails')" class="nav-item">
										<router-link :to="`/${domain}/emails`" replace class="nav-link text-center">
											Emails <span v-if="domainEmails[domain] && !isError(domainEmails[domain])" class="badge rounded-pill bg-secondary-light text-secondary">{{ domainEmails[domain].length }}</span>
										</router-link>
									</li>
								</ul>
							</nav>
						</div>

						<div :class="[isMobile ? 'offcanvas-body' : 'card-body p-2 p-lg-3']">

							<domain-overview :domain="domain"></domain-overview>
							
						</div>
					</div>

				</div>
			</div>

		</section>

		<div v-else>
			<section class="page-head text-center">
				<h2 class="py-4">Get info on any domain on the internet 👆</h2>
			</section>

			<div class="container-fluid py-4">

				<h5>📸 Your domain watchlist</h5>
				<div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-3 mb-4">
					<div v-for="domain in domainsMonitoring.slice(0, 8)" :key="`dm-monitor-${domain}`" class="col my-2">
						<router-link :to="`/${domain}`" class="card card-link">
							<div class="card-body">
								<h6 class="card-title my-0">{{ domain }}</h6>
							</div>
						</router-link>
					</div>
					<div v-if="domainsMonitoring.length > 5" class="col my-2">
						<router-link to="/domains-list?monitoring=1" class="card card-link">
							<div class="card-body">
								<p class="card-text text-center">View all</p>
							</div>
						</router-link>
					</div>
					<div v-else-if="!domainsMonitoring.length" class="col my-2">
						<div class="card bg-light border" style="border-style: dashed !important">
							<div class="card-body">
								<p class="card-text text-center">Search for a domain to start monitoring</p>
							</div>
						</div>
					</div>
				</div>

				<h5>⚡️ Recent detected changes</h5>
				<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 gx-3 mb-4">
					<div v-for="change in recentChanges.slice(0, 8)" :key="`recent-change-${change.id}`" class="col my-2">
						<domain-activity-card :activity="change" />
					</div>
				</div>

				<h5><router-link to="/collections" class="text-dark">🗃 Domain collections</router-link></h5>
				<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
					<div v-for="list in publicLists.slice(0, 8)" :key="`list-${list.id}`" class="col my-2">
						<list-card :list="list" />
					</div>
				</div>
				
			</div>
		</div>

		<div class="modal fade" id="modal-search-tlds" tabindex="-1" role="dialog" aria-labelledby="modal-search-tlds-label" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content border-0 shadow-sm">
					<div class="modal-header">
						<h5 class="modal-title" id="modal-search-tlds-label">Personalize TLDs</h5>
						<button type="button" class="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p>Choose which TLDs are shown for search.</p>

						<draggable
							:list="searchTlds"
							class="list-group list-group-tlds mb-1"
							ghost-class="ghost"
							@end="saveSearchTlds"
						>
							<div class="list-group-item on-parent" v-for="tld in searchTlds" :key="tld">
								<div class="row">
									<div class="col"><span class="text-muted">.</span>{{ tld }}</div>
									<div class="col-auto">
										<span class="cursor-pointer show-on-hover" @click="removeSearchTld(tld)">❌</span>
									</div>
								</div>
							</div>
						</draggable>

						<p><small class="text-muted">Drag TLDs to reorder the list</small></p>

						<form @submit.prevent="addSearchTld" class="row gx-3 align-items-center">
							<div class="col-8">
								<label class="visually-hidden" for="search-tld-new">TLD</label>
								<input type="text" class="form-control" id="search-tld-new" v-model="searchTldNew" placeholder="New TLD" required minlength="2" maxlength="64">
							</div>

							<div class="col-4">
								<button type="submit" class="btn btn-sm btn-primary">Add TLD</button>
							</div>
						</form>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.h-scroll {
	flex-wrap: nowrap;
	min-width: 100%;
	overflow-x: scroll;
}

.h-scroll > * {
	white-space: nowrap;
}

.label-keyword {
	font-size: 15px;
}

.list-group-tlds .list-group-item:hover {
	background-color: #e2e8f0;
}
</style>

<script>
import Vue from 'vue'
import { debounce } from 'vue-debounce'
import { parse } from 'tldts'
import { mapGetters, mapState } from 'vuex'
import { Offcanvas } from 'bootstrap'
import { differenceInDays } from 'date-fns'
import { isEqual } from 'lodash-es'

import domainsApi from "@/api.js"
import { textToSpeech } from "@/utils.js"

import draggable from 'vuedraggable'
//import Search from '@/components/Search.vue'
import DomainMonitorButton from '@/components/DomainMonitorButton.vue'
import DomainListsButton from '@/components/DomainListsButton.vue'
import DomainOverview from '@/components/DomainOverview.vue'
import DomainActivityCard from '@/components/DomainActivityCard.vue'
import ListCard from '@/components/ListCard.vue'

export default {
	name: 'TLDs',
	components: {
		draggable,
		//Search,
		DomainMonitorButton,
		DomainListsButton,
		DomainOverview,
		DomainActivityCard,
		ListCard,
	},
	data() {
		return {
			labelAvailability: {
				registered: 'registered',
			},
			domain:			null,
			keyword: null,
			//domainData:		{},
			tldList:		[],
			$domainInfo:	null,
			$domainOffcanvas: null,

			recentChanges: [],

			searchTlds: (localStorage.getItem('dmns-search-tlds') || 'com,net,org,co,app,ai,biz,dev,link,live,online,site,top,tv,us,xyz').split(','),
			searchTldNew: '',
		}
	},
	computed: {
		...mapGetters([ 'auth', 'isStaff', 'isBot' ]),
		...mapState(['isMobile', 'publicLists', 'badgeAvailability', 'domainsMonitoring', 'keywordStats', 'domainData', 'domainDnsRecords', 'domainEmails', 'betas']),
		keywordTypes() {
			const types = []

			if (this.keyword) {
				if (this.keyword.is_word) {
					types.push('english word')
				}
				if (this.keyword.is_brand) {
					types.push('brand')
				}
				if (this.keyword.is_name) {
					types.push('name')
				}
				if (this.keyword.is_location) {
					types.push('location')
				}
			}

			return types
		},
		keywordDescription() {
			let text = ''

			if (this.keyword) {
				const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
				text = formatter.format(this.keywordTypes)
			}

			return text
		},
	},
	created() {
		//console.log('TLDs', 'created')
		this.domain = this.$route.query.d

		this.$store.dispatch('accountInfo').then(account => {
			if (account.custom_tlds?.length && !isEqual(this.searchTlds, account.custom_tlds)) {
				this.searchTlds = account.custom_tlds
			}
		})

		if (this.$route.query.q) {
			this.loadKeywords()
		} else {
			this.loadLists()
		}
	},
	mounted() {
		this.$domainInfo = document.querySelector('.col-domain-info')
	},
	methods: {
		alert,
		textToSpeech,

		loadLists() {
			domainsApi.get('/monitoring/recent-changes').then(({ data }) => {
				this.recentChanges.push(...data)
			})
		},

		loadKeywords() {
			if (!this.$route.query.q) {
				return
			}

			//if (!this.tldList.filter(tld => tld.domain === this.$route.query.q).length) {
				this.tldList = this.handleKeyword(this.$route.query.q)
			//}

			const active = this.tldList.filter(d => d.active)
			this.domain = active[0].domain
			//this.$router.push({ name: 'Domains', query: { q: this.$route.query.q, d: active[0].domain } })

			this.loadDomainsData(this.tldList.map(t => t.domain))
		},
		handleKeyword(text) {
			const parsed = parse(text)
			const suggestions = {
				a:	'app',
				ap:	'app',
				c:	'com',
				i:	'io',
				n:	'net',
			}

			let keyword = ''
			let tld = ''

			if (parsed.domain && parsed.isIcann) {
				keyword = parsed.domainWithoutSuffix
				tld = parsed.publicSuffix
			} else if (parsed.domain && suggestions[parsed.publicSuffix]) {
				keyword = parsed.domainWithoutSuffix
				tld = suggestions[parsed.publicSuffix]
			} else if (parsed.domain) {
				keyword = parsed.domainWithoutSuffix + parsed.publicSuffix
			} else {
				if (text.endsWith('.')) {
					text = text.slice(0, -1)
				}
				keyword = text
			}

			let tlds = this.searchTlds

			// Some tlds don't accept less than 2 char domains
			if (keyword.length < 3) {
				const noShort = ['info', 'me']
				tlds = tlds.filter(t => !noShort.includes(t))
			}

			// complete tlds
			if (tld === 'uk') {
				tlds.unshift('co.uk')
			} else if (tld === 'co.uk') {
				tlds.unshift('uk')
			}

			if (tld && !tlds.includes(tld)) {
				tlds.unshift(tld)
			}

			let hasActive = false

			let list = tlds.map(t => {
				if (t === tld) {
					hasActive = true
				}

				return {
					domain:			`${keyword}.${t}`,
					keyword,
					tld:			t,
					availability:	'unknown',
					active:			t === tld,
				}
			})

			if (!hasActive) {
				list[0].active = true
			}

			this.loadKeywordStats(keyword)

			return list
		},
		loadDomainsData: debounce(function(domains) {
			domains.forEach(domain => {
				if (domain === this.domain) {
					if (!this.isBot) {
						this.$store.dispatch('loadDomainDnsRecords', { domain })
					}

					if (this.betas.includes('emails')) {
						this.$store.dispatch('loadDomainEmails', { domain })
					}
				}
				this.$store.dispatch('loadDomainData', { domain, mode: domain === this.domain ? 'detailed' : 'cache' }).then(() => {
					if (domain === this.domain) {
						this.$store.dispatch('loadDomainWebsiteInfo', { domain })
						this.$store.dispatch('loadDomainActivity', { domain })
					}
				})
			})
		}, 250),
		selectKeyword(keyword) {
			this.tldList = this.tldList.map(domain => {
				if (domain.domain === keyword) {
					domain.active = true
					this.domain = domain.domain
					this.$router.push({ name: 'Domains', query: { q: this.$route.query.q, d: domain.domain } })
					this.$store.dispatch('loadDomainData', { domain: domain.domain, mode: 'detailed' }).then(() => {
						if (!this.isBot) {
							this.$store.dispatch('loadDomainWebsiteInfo', { domain: domain.domain })
						}
					})

					if (!this.isBot) {
						this.$store.dispatch('loadDomainDnsRecords', { domain: domain.domain })
					}
				} else {
					domain.active = false
				}

				return domain
			})

			if (this.isMobile) {
				//this.$domainInfo.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

				if (!this.$domainOffcanvas) {
					this.$domainOffcanvas = new Offcanvas(document.querySelector('.offcanvas-bottom'), { scroll: true })
				}

				this.$domainOffcanvas.show()
			}
		},
		loadKeywordStats: debounce(function(keyword) {
			domainsApi.get(`/keywords/${keyword}`).then(({ data }) => {
				this.keyword = data
			})
			this.$store.dispatch('ev', { event: 'Keyword search', data: { feature: 'Domains', keyword } })
		}, 200),
		isError(obj) {
			return obj instanceof Error
		},
		averageAge(keyword, tlds) {
			let ages = []

			tlds.forEach(tld => {
				const d = `${keyword}.${tld}`
				if (this.domainData[d] && this.domainData[d].availability && this.domainData[d].availability === 'registered') {
					ages.push(differenceInDays(new Date(), new Date(this.domainData[d].dates.created)))
				} else {
					ages.push(0)
				}
			})

			return (ages.reduce((a, b) => a + b, 0) / ages.length / 365).toFixed(2)
		},

		updateKeyword() {
			domainsApi.put(`/keywords/${this.keyword.keyword}`, {
				is_word: this.keyword.is_word,
				is_brand: this.keyword.is_brand,
				is_name: this.keyword.is_name,
				is_location: this.keyword.is_location,
			}).then(() => {
				console.log('keyword updated')
			})
		},

		showSearchTlds() {
			this.$store.dispatch('ev', { event: 'Customize TLDs', data: { feature: 'Domains' } })
		},
		addSearchTld() {
			if (this.searchTldNew.startsWith('.')) {
				this.searchTldNew = this.searchTldNew.slice(1)
			}

			if (this.searchTldNew && /^[a-zA-Z]{2,}$|^([a-zA-Z]{2,}\.[a-zA-Z]{2,})$/.test(this.searchTldNew)) {
				this.searchTlds.push(this.searchTldNew)

				this.handleKeyword(this.$route.query.q)
				this.saveSearchTlds('TLD is added')
				this.searchTldNew = ''
			} else {
				alert('That\'s an invalid TLD')
			}
		},
		saveSearchTlds(message) {
			localStorage.setItem('dmns-search-tlds', this.searchTlds.join(','))

			if (this.auth) {
				//todo save to account
			}

			Vue.toasted.success(message || 'TLDs list is saved')
		},
		removeSearchTld(tld) {
			if (confirm(`For sure remove this extension?`)) {
				this.searchTlds = this.searchTlds.filter(t => t !== tld)
				this.saveSearchTlds('TLD is removed')
			}
		},
	},
	watch: {
		$route(to, from) {
			//console.log('R', to.query, from.query)

			if (to.query.q !== from.query.q) {
				this.loadKeywords()
			}
		}
	},
}
</script>
