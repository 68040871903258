import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueDebounce from 'vue-debounce'
import Toasted from 'vue-toasted'
import { fromUnixTime, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import VueMeta from 'vue-meta'
import 'animate.css';

import { average, truncateString } from './utils.js'

Vue.config.productionTip = false

Vue.use(vueDebounce)
Vue.use(VueMeta)
Vue.use(Toasted, {
	duration: 5000,
	position: 'bottom-right',
	router,
})

Vue.filter('dateLocal', function(value) {
	if (!value) return ''

	if (typeof value === 'number') {
		value = fromUnixTime(value)
	} else if (!(value instanceof Date)) {
		value = new Date(value)
	}

	return value.toLocaleDateString()
})

Vue.filter('dateToNow', function(value, strict) {
	if (!value) return ''

	if (typeof value === 'number') {
		value = fromUnixTime(value)
	} else if (!(value instanceof Date)) {
		value = new Date(value)
	}

	return strict ? formatDistanceToNowStrict(new Date(value), { addSuffix: true }) : formatDistanceToNow(new Date(value), { addSuffix: true })
})

Vue.filter('currency', function(value) {
	if (value === undefined) return ''

	return Number(value).toLocaleString('en-US', {
		style: 'currency',
		currency: 'usd',
	}).replace('.00', '')
})

Vue.filter('numStat', function(number) {
	if (number === undefined) return ''

	number = Number(number)

	if (number > 10000) {
		number = `${(Math.floor(number / 10000) * 10000).toLocaleString()}+`
	} else if (number > 1000) {
		number = `${(Math.floor(number / 1000) * 1000).toLocaleString()}+`
	} else if (number > 100) {
		number = `${Math.floor(number / 100) * 100}+`
	}

	return number
})

Vue.filter('average', function(array, decimals = 2) {
	if (!Array.isArray(array)) return ''

	return average(array).toFixed(decimals)
})

Vue.filter('truncateString', truncateString)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
