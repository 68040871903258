export default [
  "Generating witty dialog…",
  "Swapping time and space…",
  "Tokenizing real life…",
  "Bending the spoon…",
  "Filtering morale…",
  "We need a new fuse…",
  "Upgrading Windows…",
  "640K ought to be enough for anybody",
  "The architects are still drafting",
  "The bits are breeding",
  "We're building the buildings as fast as we can",
  "Don't worry - a few bits tried to escape, but we caught them",
  "Checking the gravitational constant in your locale…",
  "The server is powered by a lemon and two electrodes.",
  "We're testing your patience",
  "As if you had any other choice",
  "The satellites move into position",
  "The bits are flowing slowly today",
  "It's still faster than you could draw it",
  "My other loading screen is much faster.",
  "Reconfoobling energymotron…",
  "Just count to 10",
  "Counting backwards from Infinity",
  "Embiggening Prototypes",
  "We're making you a cookie.",
  "Creating time-loop inversion field",
  "Spinning the wheel of fortune…",
  "Loading the enchanted bunny…",
  "Computing chance of success",
  "Looking for exact change",
  "All I really need is a kilobit.",
  "I feel like im supposed to be loading something…",
  "What do you call 8 Hobbits? A Hobbyte 🥁",
  "Should have used a compiled language…",
  "Adjusting flux capacitor…",
  "Please wait until the sloth starts moving.",
  "Don't break your screen yet!",
  "I swear it's almost done.",
  "Let's take a mindfulness minute…",
  "Listening for the sound of one hand clapping…",
  "Keeping all the 1's and removing all the 0's…",
  "Putting the icing on the cake. The cake is not a lie…",
  "Cleaning off the cobwebs…",
  "We need more dilithium crystals",
  "Where did all the internets go",
  "Time flies when you’re having fun.",
  "Spinning the hamster…",
  "Stay awhile and listen…",
  "Load it and they will come",
  "Convincing AI not to turn evil…",
  "There is no spoon. Because we are not done loading it",
  "Computing the secret to life, the universe, and everything.",
  "I love my job only when I'm on vacation…",
  "i'm not lazy, I'm just relaxed!!",
  "I’ve got problem for your solution…",
  "Where there’s a will, there’s a relative",
  "Adults are just kids with money",
  "I think I am, therefore, I am. I think.",
  "May the forks be with you",
  "Constructing additional pylons…",
  "If you type Google into Google you can break the internet",
  "Well, this is embarrassing…",
  "What is the airspeed velocity of an unladen swallow?",
  "I'm going to walk the dog",
  "Dividing by zero…",
  "We’re going to need a bigger boat",
  "Cracking military-grade encryption…",
  "Simulating traveling salesman…",
  "Entangling superstrings…",
  "Twiddling thumbs…",
  "Searching for plot device…",
  "Please hold on as we reheat our coffee",
  "Kindly hold on as we convert this bug to a feature…",
  "Installing dependencies",
  "Switching to the latest JS framework…",
  "BRB, working on my side project",
  "Let's hope it's worth the wait",
  "Ordering 1s and 0s…",
  "Updating dependencies…",
  "Whatever you do, don't look behind you…",
  "Waiting Daenerys say all her titles…",
  "Feel free to spin in your chair",
  "Bored of slow loading spinner, buy more RAM!",
  "Downloading more RAM…",
  "Initializing the initializer…",
  "Optimizing the optimizer…",
  "Never let a computer know you're in a hurry.",
  "Shovelling coal into the server",
  "Pushing some pixels…",
  "How about this weather, eh?",
  "Updating Updater…",
  "Debugging Debugger…",
  "Reading Terms and Conditions for you.",
  "Working, WORKING…",
  "Patience! This is difficult, you know…",
  "Your time is very important to us. Please wait a bit more…",
  "Time flies like an arrow; fruit flies like a banana",
  "Still faster than Windows update",
  "Still faster than Android update",
  "Obfuscating quantum entaglement",
  "Chuck Norris doesn't scroll with a mouse. He uses a lion.",
  "Chuck Norris can delete the Recycling Bin.",
  "Preventing robot uprising …",
  "Hacking into the Main Frame…",
  "Waiting for something to load sucks…",
  "Oh, are you waiting for me?",
  "Oh you are an eager beaver",
  "Doing the heavy lifting",
  "Attaching Beards to Dwarves",
  "Creating Randomly Generated Feature",
  "Doing Something You Don't Wanna Know About",
  "Doing The Impossible",
  "Hitting Your Keyboard Won't Make This Faster",
  "Outfitting Pigs With Wings",
]
