<template>
	<div class="domain-page">

		<!--
		<section class="page-head">
			<div class="container text-center py-3 py-lg-5">
				<h4 class="mb-3">Search for any domain or TLD</h4>
				<div class="row justify-content-center">
					<div class="col-10 col-md-7">
						<search :query="$route.params.domain || ''"></search>
					</div>
				</div>
			</div>
		</section>
		-->

		<section class="container py-5">

			<div class="row justify-content-center pt-4">

				<div class="col-lg-10">

					<div class="card">
						<div class="card-header">
							<div class="row align-items-center">
								<div class="col">
									<h1 v-if="dData" class="display-5 mb-2 mb-lg-1">
										<router-link :to="`/domains?q=${domain}&d=${domain}`">{{ dData.keyword }}</router-link>.{{ dData.tld }}
									</h1>
									<h1 v-else class="display-5 mb-2 mb-lg-1">{{ domain }}</h1>
								</div>
								<div class="col-lg-auto my-1">
									<a v-if="dData && dData.availability === 'registered'" :href="`http://${domain}`" class="btn btn-sm btn-outline-secondary my-1 me-2" target="_blank" rel="nofollow"><img :src="`https://logo.clearbit.com/${domain}?size=14`" @error="$event.target.classList.add('d-none')" width="14" height="14" :alt="domain" /> Visit site</a>
									<domain-monitor-button :domain="domain" class="my-1 me-2"></domain-monitor-button>
									<domain-lists-button :domain="dData" class="my-1 me-2"></domain-lists-button>
									<button class="btn btn-sm btn-outline-warning my-1" data-bs-toggle="modal" data-bs-target="#modal-private-note">Add note</button>
									<!--<button class="btn btn-sm btn-outline-secondary ms-1">⏍ Share</button>-->
								</div>
							</div>
						</div>
						<div class="card-header py-0">
							<nav class="overflow-auto domain-tabs-scroll">
								<ul class="nav nav-tabs">
									<li class="nav-item">
										<router-link :to="`/${domain}`" replace class="nav-link text-center" :class="{active: !tab, 'border-danger': dData && isError(dData)}">
											<strong>Overview</strong>
											<br />
											<small class="text-muted">{{ dData ? (dData.availability ? (labelAvailability[dData.availability] || dData.availability) : 'error') : '..' }}</small>
										</router-link>
									</li>
									<li class="nav-item">
										<router-link :to="`/${domain}/whois`" replace class="nav-link text-center" :class="{active: tab === 'whois'}">
											<strong>WHOIS</strong>
											<br />
											<small class="text-muted">{{ dWhois ? (Object.keys(dWhois)[0] || 'error') : '..' }}</small>
										</router-link>
									</li>
									<li class="nav-item" v-if="dWhois && Object.keys(dWhois).length > 1">
										<router-link :to="`/${domain}/whois-registrar`" replace class="nav-link text-center" :class="{active: tab === 'whois-registrar'}">
											<strong>WHOIS</strong>
											<br />
											<small class="text-muted">{{ dWhois ? (Object.keys(dWhois)[1] || 'error') : '..' }}</small>
										</router-link>
									</li>
									<li class="nav-item">
										<router-link :to="`/${domain}/name-servers`" replace class="nav-link text-center" :class="{active: tab === 'name-servers'}">
											<strong>Name Servers</strong>
											<br />
											<small class="text-muted">{{ dNs ? (isError(dNs) ? 'error' : dNs.length + ' found') : '..' }}</small>
										</router-link>
									</li>
									<li class="nav-item">
										<router-link :to="`/${domain}/dns-records`" replace class="nav-link text-center" :class="{active: tab === 'dns-records'}">
											<strong>DNS Records</strong>
											<br />
											<small class="text-muted">{{ dDns ? (isError(dDns) ? 'error' : Object.values(dDns).flat().length + ' found') : '..' }}</small>
										</router-link>
									</li>
									<li class="nav-item">
										<router-link :to="`/${domain}/history`" replace class="nav-link text-center" :class="{active: ['activity', 'history'].includes(tab)}">
											<strong>History</strong>
											<br />
											<small class="text-muted">{{ dActivity ? (isError(dActivity) ? 'error' : dActivity.length + ' found') : '..' }}</small>
										</router-link>
									</li>
									<li v-if="isStaff" class="nav-item">
										<router-link :to="`/${domain}/emails`" replace class="nav-link text-center" :class="{active: tab === 'emails'}">
											<strong>Emails</strong>
											<br />
											<small class="text-muted">{{ dEmails ? (isError(dEmails) ? 'error' : dEmails.length + ' found') : '..' }}</small>
										</router-link>
									</li>
								</ul>
							</nav>
						</div>
						<div class="card-body p-2 p-lg-3">

							<div v-if="!tab">

								<domain-overview :domain="domain"></domain-overview>

							</div><!-- End Overview section -->

							<div v-else-if="tab === 'whois' || tab === 'whois-registrar'">
								<div v-if="!dWhois">
									<loader></loader>
								</div>
								<div v-else-if="isError(dWhois)">
									<div class="alert alert-danger">{{ dWhois.message }}</div>
								</div>
								<div v-else-if="dWhois && tab === 'whois'">
									<p class="text-muted">This info was returned by Registry WHOIS server <span class="badge bg-secondary-light text-secondary">{{ Object.keys(dWhois)[0] }}</span> and lightly formatted to be easier to read. Registrar WHOIS may include more detailed info.</p>

									<div v-for="group in whoisGroup" :key="group.title" class="bg-light rounded p-2 mb-3">
										<h4 class="mx-1 my-2">{{ group.title }}</h4>

										<table class="table table-hover">
											<tbody>
												<tr v-for="(content, label) in dWhois[Object.keys(dWhois)[0]]" :key="label" v-show="group.fields.includes(label)">
													<th>{{ label }}</th>
													<td>
														<div v-if="typeof content === 'string'">{{ content }}</div>
														<div v-else>
															<p class="mb-1" v-for="(contentItem, index) in content" :key="index">{{ contentItem }}</p>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div class="bg-light rounded p-2 mb-3">
										<h4 class="mx-1 my-2">WHOIS Info</h4>

										<table class="table table-hover">
											<tbody>
												<tr v-for="(content, label) in dWhois[Object.keys(dWhois)[0]]" :key="label" v-show="!whoisGroupFields.includes(label)">
													<th>{{ label }}</th>
													<td>
														<div v-if="typeof content === 'string'">{{ content }}</div>
														<div v-else>
															<p class="mb-1" v-for="(contentItem, index) in content" :key="index">{{ contentItem }}</p>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<p v-if="!domainsMonitoring.includes(domain)" class="text-center">Want to be notified when changes happen to WHOIS data? <domain-monitor-button :domain="domain"></domain-monitor-button></p>
								</div>
								<div v-else-if="dWhois && tab === 'whois-registrar'">
									<p class="text-muted">This info was returned by Registrar WHOIS server <span class="badge bg-secondary-light text-secondary">{{ Object.keys(dWhois)[1] }}</span> and lightly formatted to be easier to read.</p>

									<div v-for="group in whoisGroup" :key="group.title" class="bg-light rounded p-2 mb-3">
										<h4 class="mx-1 my-2">{{ group.title }}</h4>

										<table class="table table-hover">
											<tbody>
												<tr v-for="(content, label) in dWhois[Object.keys(dWhois)[1]]" :key="label" v-show="group.fields.includes(label)">
													<th>{{ label }}</th>
													<td>
														<div v-if="typeof content === 'string'">{{ content }}</div>
														<div v-else>
															<p class="mb-1" v-for="(contentItem, index) in content" :key="index">{{ contentItem }}</p>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div class="bg-light rounded p-2 mb-3">
										<h4 class="mx-1 my-2">WHOIS Info</h4>

										<table class="table table-hover">
											<tbody>
												<tr v-for="(content, label) in dWhois[Object.keys(dWhois)[1]]" :key="label" v-show="!whoisGroupFields.includes(label)">
													<th>{{ label }}</th>
													<td>
														<div v-if="typeof content === 'string'">{{ content }}</div>
														<div v-else>
															<p class="mb-1" v-for="(contentItem, index) in content" :key="index">{{ contentItem }}</p>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<p v-if="!domainsMonitoring.includes(domain)" class="text-center">Want to be notified when changes happen to WHOIS data? <domain-monitor-button :domain="domain"></domain-monitor-button></p>
								</div>
							</div><!-- End WHOIS section -->

							<div v-else-if="tab === 'dns-records'">
								<div v-if="!dDns">
									<loader></loader>
								</div>
								<div v-else-if="isError(dDns)">
									<div class="alert alert-danger">{{ dDns.message }}</div>
								</div>
								<div v-else-if="dDns">
									<p v-if="dData && dData.ns.length" class="mb-2">These DNS Records were retrieved from <span class="badge bg-secondary-light text-secondary">{{ dData.ns[0] }}</span> Name Server.</p>
									<p v-if="dData && dData.services.dns.length" class="">"{{ domain }}" uses {{ dData.services.dns.map(d => d.name).join(', ') }} as DNS provider.</p>

									<div class="card-table my-4">
										<table class="table table-sm table-hover">
											<thead>
												<tr>
													<th>Type</th>
													<th>Name</th>
													<th>TTL</th>
													<th>Content</th>
												</tr>
											</thead>
											<tbody>
												<template v-for="(group, type) in dDnsGrouped">
													<tr v-for="(dns, index) in group" :key="`${type}-${index}`" class="on-parent">
														<td>
															<a
																:href="`https://www.cloudflare.com/learning/dns/dns-records/dns-${dns.type.toLowerCase()}-record/`"
																target="_blank"
																rel="nofollow"
																class="badge"
																:class="{
																	'bg-success-lighter text-success': ['A', 'AAAA', 'CNAME'].includes(dns.type),
																	'bg-primary-lighter text-primary': ['MX'].includes(dns.type),
																	'bg-info-lighter text-info': ['NS', 'SOA', 'TXT'].includes(dns.type),
																	'bg-light text-gray': !['NS', 'SOA', 'A', 'AAAA', 'CNAME', 'MX', 'TXT'].includes(dns.type),
																}"
																>{{ dns.type }}</a
															>
														</td>
														<td>
															<a v-if="['A', 'AAAA', 'CNAME'].includes(dns.type) && !dns.name.startsWith('*.')" :href="`http://${dns.name}`" target="_blank" rel="nofollow">{{ dns.name }} <small class="show-on-hover">❐</small></a>
															<span v-else :class="{ 'badge border text-secondary': dns.name.startsWith('*.') }">{{ dns.name }}</span>
														</td>
														<td>
															<span class="ttl" :title="`${dns.ttl} seconds`">{{ toTime(dns.ttl) }}</span>
														</td>
														<td>
															<p v-for="(value, index) in dns.value" :key="index" class="mb-1">
																<span v-if="isIP(value) && ips[value]">{{ value }} <small class="badge bg-secondary-light text-dark mx-1">{{ ips[value].org }}</small> <span class="badge bg-light text-dark mx-1">{{ [ips[value].city, ips[value].country].join(', ') }}</span></span>
																<span v-else>{{ value }}</span>
															</p>
														</td>
													</tr>
												</template>
												<!--
												<tr v-if="!group.length">
													<td>
														<span class="badge badge-light">{{ type }}</span>
													</td>
													<td colspan="3" class="text-muted">No {{ type }} records</td>
												</tr>
												-->
											</tbody>
										</table>
									</div>

									<p v-if="!domainsMonitoring.includes(domain)" class="text-center">Want to be notified when changes happen to DNS Records? <domain-monitor-button :domain="domain"></domain-monitor-button></p>
								</div>
							</div><!-- End DNS Records section -->

							<div v-else-if="tab === 'name-servers'">
								<div v-if="!dNs">
									<loader></loader>
								</div>
								<div v-else-if="isError(dNs)">
									<div class="alert alert-danger">{{ dNs.message }}</div>
								</div>
								<div v-else-if="dNs">

									<div class="card-table">
										<table class="table table-sm table-hover">
											<thead>
												<tr>
													<th scope="col">Name server</th>
													<th>SOA Serial</th>
													<th>IP</th>
													<th>Response Time</th>
													<th>ISP</th>
													<th>Location</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="ns in dNs" :key="ns.ns">
													<td>{{ ns.ns }}</td>
													<td>{{ ns.soaSerial }}</td>
													<td>
														<p v-for="ip in ns.IPv4" :key="ip" class="mb-1">{{ ip }}</p>
														<p v-for="ip in ns.IPv6" :key="ip" class="mb-1"><small>{{ ip }}</small></p>
													</td>
													<td>
														<p v-for="(time, index) in ns.responseTimev4" :key="`rt4-${index}`" class="mb-1">
															<span v-if="time">{{ time }}ms</span>
															<span v-else class="text-muted">couldn't get</span>
														</p>
														<p v-for="(time, index) in ns.responseTimev6" :key="`rt6-${index}`" class="mb-1">
															<span v-if="time">{{ time }}ms</span>
															<span v-else class="text-muted">couldn't get</span>
														</p>
													</td>
													<td>
														<p v-for="ip in [...ns.IPv4, ...ns.IPv6]" :key="`isp-ip-${ip}`" class="mb-1">
															<span v-if="ips[ip]">{{ ips[ip].org }}</span>
														</p>
													</td>
													<td>
														<p v-for="ip in [...ns.IPv4, ...ns.IPv6]" :key="`isp-ip-${ip}`" class="mb-1">
															<span v-if="ips[ip]">{{ [ips[ip].city, ips[ip].country].filter(Boolean).join(', ') }}</span>
														</p>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div><!-- End Name Servers section -->

							<div v-else-if="['activity', 'history'].includes(tab)">
								<div v-if="!dActivity">
									<loader></loader>
								</div>
								<div v-else-if="isError(dActivity)">
									<div class="alert alert-danger">{{ dActivity.message }}</div>
								</div>
								<div v-else-if="dActivity">

									<p class="lead">Timeline of notes and changes known about <strong>{{ domain }}</strong></p>

									<div v-if="isStaff" class="bg-info-lighter bg-light p-3 rounded mb-3">
										<h6>Add a note</h6>

										<form @submit.prevent="addNote" class="row gx-2 align-items-center mb-3">
											<div class="col-6">
												<label class="visually-hidden" for="domain-note">Note</label>
												<input type="text" class="form-control" id="domain-note" v-model="newNote.data" required placeholder="Note">
											</div>

											<div class="col-3">
												<label class="visually-hidden" for="domain-note-date">Date</label>
												<input type="date" class="form-control" id="domain-note-date" v-model="newNote.created_at" required />
											</div>

											<div class="col-2">
												<label class="visually-hidden" for="domain-note-time">Time</label>
												<input type="time" class="form-control" id="domain-note-time" v-model="newNote.created_at_time" required />
											</div>

											<div class="col-1">
												<button type="submit" class="btn btn-primary">Save</button>
											</div>
										</form>

										<hr />

										<form @submit.prevent="addLink" class="">
											<label class="visually-hidden" for="domain-link">Link</label>

											<div class="row gx-2 align-items-center">
												<div class="col-11">
													<input type="url" class="form-control" id="domain-link" v-model="newLink" required placeholder="Link">
												</div>
												<div class="col-1">
													<button type="submit" class="btn btn-primary">Save</button>
												</div>
											</div>
										</form>
									</div>

									<div class="domain-timeline">
										<div v-for="activity in dActivity" :key="`domain-timeline-${activity.id}`" class="row on-parent">
											<div class="col-3 pt-2 border-end text-end">
												<p class="mb-1">{{ (new Date(activity.created_at)).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' }) }} <span class="timeline-record-type">{{ domainActivityTypes[activity.type] || '➕' }}</span></p>
												<p class="text-muted mb-0 show-on-hover">{{ activity.created_at | dateToNow }}</p>
												<p v-if="isStaff" class="mb-0 mt-2 show-on-hover">
													<code>{{ activity.id }}</code>
													<button class="btn btn-sm btn-outline-danger ms-2" @click="deleteDomainActivity(activity)">Delete</button>
												</p>
											</div>
											<div class="col-9 pt-1 ps-4 pb-4">
												<div v-if="activity.type === 'whois'" class="bg-light rounded p-3">
													<h4 v-if="activity.text" class="mb-3">{{ activity.text }}</h4>

													<p class="mb-2">Changed detected in WHOIS:</p>

													<div class="mb-1" v-for="(item, index) in activity.data" :key="index">
														<div v-if="item.kind === 'E'">
															<p class="bg-diff-line-deleted px-2 mb-0">{{ item.path.slice(1).join(', ') }}: <span class="bg-diff-deleted px-1">{{ item.lhs }}</span></p>
															<p class="bg-diff-line-new px-2 mb-2">{{ item.path.slice(1).join(', ') }}: <span class="bg-diff-new px-1">{{ item.rhs }}</span></p>
														</div>

														<p v-else-if="item.kind === 'D'" class="bg-diff-line-deleted px-2 mb-2">{{ item.path.slice(1).join(', ') }}: {{ item.lhs }}</p>
														<p v-else-if="item.kind === 'A' && item.item.kind === 'D'" class="bg-diff-line-deleted px-2 mb-2">{{ item.path.slice(1).join(', ') }}, {{ item.index }}: {{ item.item.lhs }}</p>

														<p v-else-if="item.kind === 'N'" class="bg-diff-line-new px-2 mb-2">{{ item.path.slice(1).join(', ') }}: {{ item.rhs }}</p>
														<p v-else-if="item.kind === 'A' && item.item.kind === 'N'" class="bg-diff-line-new px-2 mb-2">{{ item.path.slice(1).join(', ') }}, {{ item.index }}: {{ item.item.rhs }}</p>

														<span v-else>{{ item }}</span>
													</div>

													<div v-if="isStaff" class="bg-danger-lighter rounded p-2">
														<div class="row align-items-center text-center">
															<div class="col">
																<p class="mb-2">WHOIS change code</p>
																<p class="mb-0"><span v-if="activity.detected_changes" class="badge bg-light text-dark">{{ activity.detected_changes }}</span></p>
															</div>
															<div class="col-1">
																{{ activity.expected_code && activity.detected_changes == activity.expected_code ? '✅' : '❌' }}
															</div>
															<div class="col">
																<p class="mb-2">Expected code</p>
																<p class="mb-0">
																	<span v-if="activity.expected_code" class="badge bg-light text-dark cursor-pointer" @click="whoisActivityAddCode(activity)">{{ activity.expected_code }}</span>
																	<button v-else class="btn btn-outline-dark btn-sm" @click="whoisActivityAddCode(activity)">Add expected</button>
																</p>
															</div>
														</div>
													</div>
												</div>
												<div v-else-if="activity.type === 'note'" class="bg-info-lighter rounded p-3">
													<p class="mb-0" v-html="enrichContent(activity.data)"></p>
												</div>
												<div v-else-if="activity.type === 'private-note'" class="bg-warning-lighter rounded p-3">
													<p class="mb-0">{{ activity.data }}</p>
												</div>
												<div v-else-if="activity.type === 'sale'" class="bg-info-lighter rounded p-3">
													<p class="mb-0">
														<strong>SALE</strong>: {{ activity.data.text }}<span v-if="activity.data.source === 'namebio'">. Source <a :href="`https://namebio.com/${domain}`" target="_blank">NameBio</a></span>
													</p>
												</div>
												<a v-else-if="activity.type === 'url'" :href="activity.data.url" target="_blank" rel="nofollow" class="d-block bg-primary-lighter text-dark rounded p-3">
													<p v-if="!(activity.data.description || '').includes(activity.data.title)" class="lead mb-2"><img v-if="activity.data.url.includes('twitter.com/')" src="@/assets/icon-twitter.svg" height="22" alt="Link to twitter" class="me-1" /> {{ activity.data.title }}</p>
													<p class="mb-1">{{ activity.data.description }}</p>
													<p class="mb-0 text-muted">
														<small>{{ activity.data.url }}</small>
													</p>
												</a>
												<div v-else>
													<p class="mb-1">
														<span class="badge bg-blue-100 text-blue-500 text-capitalize me-2">{{ activity.type }}</span>
													</p>
													<p class="mb-2">{{ activity.data }}</p>
												</div>
											</div>
										</div>

										<div v-if="dData.availability === 'registered'" class="row on-parent">
											<div class="col-3 pt-2 border-end">
												<p class="text-end mb-1">{{ (new Date(dData.dates.created)).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' }) }} <span class="timeline-record-type">➕</span></p>
												<p class="text-end text-muted mb-0 show-on-hover">{{ dData.dates.created | dateToNow }}</p>
											</div>
											<div class="col-9 pt-1 ps-4 pb-4">
												<div class="bg-light rounded p-3">
													<h3 class="my-0">Domain is registered</h3>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div><!-- End Activity section -->

							<div v-else-if="tab === 'emails'">

								<h5>Emails from Hunter.io</h5>

								<a v-if="emailsHunter" :href="`https://hunter.io/search/${domain}?ref=dmns.app`" class="d-block border p-2 bg-light rounded">
									View <strong>{{ emailsHunter.total }}</strong> emails @{{ domain }} in Hunter.
								</a>

								<hr class="my-4" />

								<h5>Incoming emails</h5>

								<div class="card-table">
									<table class="table table-hover">
										<thead>
											<tr>
												<th>From</th>
												<th>To</th>
												<th>Subject</th>
												<th>Date</th>
												<th>📂</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="email in emailsIncoming" :key="`em-${email.id}`" :class="{'bg-primary-lighter': !email.seen}" @click="emailIncoming = email" data-bs-toggle="modal" data-bs-target="#modal-incoming-email">
												<td>
													<span v-for="from in email.from" :key="`em-${email.id}-fr-${from.address}`">{{ [from.name, from.address].filter(Boolean).join(', ') }}</span>
												</td>
												<td>
													<span v-for="to in email.to" :key="`em-${email.id}-to-${to.address}`">{{ [to.name, to.address].filter(Boolean).join(', ') }}</span>
												</td>
												<td>
													<span :class="{'fw-bolder': !email.seen}">{{ email.subject }}</span>
												</td>
												<td>{{ email.date | dateLocal }}</td>
												<td>{{ email.attachments.length }}</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div v-if="this.betas.includes('emails')">
									<hr class="my-4" />

									<div v-if="!dEmails">
										<loader></loader>
									</div>
									<div v-else-if="isError(dEmails)">
										<div class="alert alert-danger">{{ dEmails.message }}</div>
									</div>
									<div v-else-if="dEmails" class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-2">

										<table class="table table-hover">
											<thead>
												<tr>
													<th></th>
													<th>Name</th>
													<th>Info</th>
													<th>Email</th>
													<th>Links</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="person in dEmails" :key="`domain-${domain}-em-${person.id}`">
													<td>
														<a v-if="person.photo" :href="person.photo" target="_blank" rel="noopener noreferer"><img :src="person.photo" :alt="person.name" referrerpolicy="no-referrer" height="42" class="rounded-circle" /></a>
													</td>
													<td>
														<p class="mb-0">{{ person.name }}</p>
														<p v-if="person.dob || person.infos.birth_year" class="mb-0">
															<span v-if="person.dob" class="badge bg-info-lighter text-info">{{ person.dob | dateToNow }}</span>
															<span v-else-if="person.infos.birth_year" class="badge bg-info-lighter text-info">{{ new Date().getFullYear() - person.infos.birth_year }}</span>
														</p>
													</td>
													<td>
														
														<p v-if="person.skills" class="mb-0">{{ person.skills | truncateString(25) }}</p>
													</td>
													<td>
														<p v-for="email in person.emails" :key="`dm-${domain}-p-${person.id}-${email}`" class="mb-0">{{ email }}</p>
													</td>
													<td>
														<a v-for="link in person.links" :key="`dm-${domain}-p-${person.id}-${link}`" :href="link" target="_blank" rel="noopener noreferer" class="mx-1">
															<span v-if="link.includes('linkedin.com')"><img src="https://simpleicons.org/icons/linkedin.svg" height="16" alt="link" /></span>
															<span v-else-if="link.includes('instagram.com')"><img src="https://simpleicons.org/icons/instagram.svg" height="16" alt="link" /></span>
															<span v-else-if="link.includes('twitter.com')"><img src="https://simpleicons.org/icons/twitter.svg" height="16" alt="link" /></span>
															<span v-else-if="link.includes('github.com')"><img src="https://simpleicons.org/icons/github.svg" height="16" alt="link" /></span>
															<span v-else-if="link.includes('facebook.com')"><img src="https://simpleicons.org/icons/facebook.svg" height="16" alt="link" /></span>
															<span v-else>🔗</span>
														</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div><!-- End Emails section -->

						</div>
					</div>

				</div>
			</div>

		</section>

		<div class="modal fade" id="modal-private-note" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content border-0 shadow-sm">
					<div class="modal-header">
						<h5 class="modal-title my-0">Private note</h5>
						<button type="button" class="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>

					<form @submit.prevent="addPrivateNote">
						<div class="modal-body">
							<p>Add a private note for this domain.</p>

							<div v-if="auth" class="form-group">
								<textarea class="form-control" rows="3" v-model="newNote.data" placeholder="Keep track of domain or website changes, inquiries to owner, etc" required></textarea>
								<span class="form-text">🔒 Only you can see this note</span>
							</div>
							<div v-else class="bg-light p-3">
								<p class="mb-0">🔒 Log in or create a FREE account to add and view notes</p>
							</div>
						</div>

						<div v-if="auth" class="modal-footer">
							<button class="btn btn-primary">Add note</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-incoming-email" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content border-0 shadow-sm">
					<div class="modal-header">
						<h5 class="modal-title my-0">{{ emailIncoming ? emailIncoming.subject : '...' }}</h5>
						<button type="button" class="btn-close text-muted" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>

					<div v-if="emailIncoming" class="modal-body">

						<p class="mb-1">ID: <strong>{{ emailIncoming.id }}</strong></p>
						<p class="mb-1">Message ID: <strong>{{ emailIncoming.message_id }}</strong></p>
						<p class="mb-1">Date: <strong>{{ emailIncoming.date }}</strong></p>
						<p class="mb-1">Envelope: <strong>{{ emailIncoming.envelope }}</strong></p>
						<p class="mb-1">From: <span v-for="from in emailIncoming.from" :key="`emm-${emailIncoming.id}-fr-${from.address}`" class="badge bg-light text-dark">{{ [from.name, from.address].filter(Boolean).join(', ') }}<span v-if="from.address == emailIncoming.envelope.mailFrom.address"> ✅</span></span></p>
						<p class="mb-1">To: <span v-for="to in emailIncoming.to" :key="`emm-${emailIncoming.id}-to-${to.address}`" class="badge bg-info-lighter text-dark">{{ [to.name, to.address].filter(Boolean).join(', ') }}<span v-if="emailIncoming.envelope.rcptTo.find(tt => tt.address == to.address)"> ✅</span></span></p>
						<p>Attachments:
							<span v-for="att in emailIncoming.attachments" :key="`em-${att.size}`" class="badge bg-light text-muted">
								{{ att.filename || '(-)' }} ({{ att.contentType }}, {{ Math.round(att.size / 1024) }}kb)
								<!-- <template v-for="(val, label) in att">
									<span :key="`em-${att.size}-${label}`" v-if="label !== 'content'">{{ label }} = {{ val }}<br></span>
								</template> -->
							</span>
						</p>

						<div v-if="emailIncoming.text" v-html="emailIncoming.text.replace(/\n/g, '<br>')" class="border rounded p-2"></div>
						<iframe v-if="0 && emailIncoming.html" :src="`data:text/html;charset=utf-8,${emailIncoming.html}`" class="border rounded p-2"></iframe>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<style lang="scss">
.nav-tabs {
	flex-wrap: initial;
	border-bottom-color: transparent;

	.nav-item {
		white-space: nowrap;
	}
}

.domain-timeline {

	.timeline-record-type {
		font-size: 24px;
		margin-right: -24px;
		margin-left: 12px;
	}
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from "vue"
import { Modal } from 'bootstrap'
import ipRegex from 'ip-regex'

import domainsApi from "@/api.js"
import { enrichContent } from "@/utils.js"

import Loader from '@/components/Loader.vue'
import DomainMonitorButton from '@/components/DomainMonitorButton.vue'
import DomainListsButton from '@/components/DomainListsButton.vue'
import DomainOverview from '@/components/DomainOverview.vue'

export default {
	name: 'TLDs',
	metaInfo() {
		const title = `${this.mainDomain} domain ${(this.tab || '').replace('-', ' ')}`
		let description = `See detailed info about ${this.mainDomain} domain name: owner details, subdomains, DNS Records, domain change history and more`
		let url = `https://dmns.app/${this.mainDomain}`

		if (this.tab === 'history') {
			description = `See a history of all changes for ${this.mainDomain}: owner changes, sales, registration updates, subdomains changes or mentions in the news`
			url = `https://dmns.app/${this.mainDomain}/history`
		}

		return {
			title,
			link: [
				{ rel: 'canonical', href: url }
			],
			meta: [
				{ vmid: 'description', name: 'description', content: description },
				{ property: 'og:site_name', content: 'Domains App'},
				{ property: 'og:type', content: 'website' },
				{ property: 'og:title', content: title },
				{ property: 'og:url', content: url },
				{ property: 'og:description', content: description },
			]
		}
	},
	components: {
		//Search,
		Loader,
		DomainMonitorButton,
		DomainListsButton,
		DomainOverview,
	},
	props: ['domain', 'tab'],
	data() {
		return {
			labelAvailability: {
				registered: 'taken',
			},
			whoisGroup: [
				{
					title: 'Domain Info',
					fields: [
						'Domain Name',
						'Domain Status',
						'Name Server',
						'Domain nameservers',
						'ROID',
						'Registry Domain ID',
						'IDN',
						'Whoisprivacy',
						'Created Date',
						'Updated Date',
						'Expiry Date',
						'free-date',
						'Registration status',
						'DNSSEC',
						'DNSSEC DS Data',
						'Error',
						'ERROR',
					],
				},
				{
					title: 'Registrar',
					fields: [
						'Registrar',
						'Registrar ID',
						'Sponsoring Registrar',
						'Record maintained by',
						'Registrar Country',
						'Registrar URL',
						'Registrar WHOIS Server',
						'Registry WHOIS Server',
						'Registrar IANA ID',
						'Registrar Admin Contact',
						'Registrar Admin Email',
						'Registrar Abuse Contact Email',
						'Registrar Abuse Contact Phone',
						'Registrar Phone',
						'Registrar Fax',
						'Registrar Address',
						'Referral URL',
						'Registrar-Reseller Name',
						'Reseller',
						'Reseller URL',
						'Reseller Name',
						'Reseller Email',
						'admin-contact',
						'Abuse Contact',
						'source',
					],
				},
				{
					title: 'Contact - Registrant',
					fields: [
						'Registry Registrant ID',
						'Registrant ID',
						'Registrant Contact ID',
						'Registrant Name',
						'Registrant Organization',
						'Registrant Contact Organisation',
						'Registrant Street',
						'Registrant City',
						'Registrant State/Province',
						'Registrant Postal Code',
						'Registrant Country',
						'Registrant Email',
						'Registrant Phone',
						'Registrant Phone Ext',
						'Registrant Phone Ext.',
						'Registrant Fax',
						'Registrant Fax Ext',
						'Registrant Fax Ext.',
						'Registrant Application Purpose',
						'Registrant Nexus Category',
					],
				},
				{
					title: 'Contact - Admin',
					fields: [
						'Registry Admin ID',
						'Admin Name',
						'Admin Organization',
						'Admin Organisation',
						'Admin Street',
						'Admin City',
						'Admin State/Province',
						'Admin Postal Code',
						'Admin Country',
						'Admin Phone',
						'Admin Email',
						'Admin Phone Ext',
						'Admin Fax',
						'Admin Fax Ext',
						'Admin Phone Ext.',
						'Admin Fax Ext.',
						'Admin Application Purpose',
						'Admin Nexus Category',
					],
				},
				{
					title: 'Contact - Tech',
					fields: [
						'Registry Tech ID',
						'Tech Contact ID',
						'Tech Name',
						'Tech Contact Name',
						'Tech Organization',
						'Tech Organisation',
						'Tech Contact Organisation',
						'Tech Street',
						'Tech City',
						'Tech State/Province',
						'Tech Postal Code',
						'Tech Country',
						'Tech Phone',
						'Tech Email',
						'Tech Contact Email',
						'Tech Phone Ext',
						'Tech Fax',
						'Tech Fax Ext',
						'Tech Phone Ext.',
						'Tech Fax Ext.',
						'Tech Application Purpose',
						'Tech Nexus Category',
					],
				},
				{
					title: 'Contact - Billing',
					fields: [
						'Registry Billing ID',
						'Billing Name',
						'Billing Organization',
						'Billing Organisation',
						'Billing Street',
						'Billing City',
						'Billing State/Province',
						'Billing Postal Code',
						'Billing Country',
						'Billing Phone',
						'Billing Email',
						'Billing Phone Ext',
						'Billing Fax',
						'Billing Fax Ext',
						'Billing Phone Ext.',
						'Billing Fax Ext.',
					],
				},
			],

			$modalNote: null,
			newNote: {
				data: '',
				created_at: null,
				created_at_time: null,
			},
			newLink: '',
			domainActivityTypes: {
				whois:	'🔵',
				dns:	'🟤',
				'private-note':	'🟡',
				sale:	'💸',
				note:	'📝',
			},

			emailsHunter: null,
			emailsIncoming: [],
			emailIncoming: null,
		}
	},
	computed: {
		mainDomain() {
			return this.domainData[this.domain]?.domain || this.domain
		},
		dData() {
			return this.domainData[this.domain]
		},
		dWhois() {
			return this.domainWhois[this.domain]
		},
		dDns() {
			return this.domainDnsRecords[this.domain]
		},
		dNs() {
			return this.domainNs[this.domain]
		},
		dActivity() {
			return this.domainActivity[this.domain]
		},
		dEmails() {
			return this.domainEmails[this.domain]
		},
		dDnsGrouped() {
			const dnsData = {}

			if (this.dDns && !this.isError(this.dDns)) {
				for (const recordType in this.dDns) {
					dnsData[recordType] = {}

					this.dDns[recordType].forEach(record => {
						const group = `${record.type}-${record.name}-${record.ttl}`

						if (!dnsData[recordType][group]) {
							dnsData[recordType][group] = {
								name: record.name,
								type: record.type,
								ttl: record.ttl,
								value: [],
							}
						}

						dnsData[recordType][group].value.push(record.value)
					})

					dnsData[recordType] = Object.values(dnsData[recordType])
				}
			}

			/* TODO id MX records
			if (dnsData.MX.length) {
				this.idMX(dnsData.MX[0].value)
			}
			*/

			return dnsData
		},
		whoisGroupFields() {
			return this.whoisGroup.map(g => g.fields).flat()
		},
		...mapGetters([ 'auth', 'isStaff', 'isBot' ]),
		...mapState([ 'betas', 'domainsMonitoring', 'domainData', 'domainWhois', 'domainDnsRecords', 'domainNs', 'domainActivity', 'domainEmails', 'ips' ]),
	},
	created() {
		//console.log('TLDs', 'created')
		//this.loadDomainData(this.domain)
		this.$store.dispatch('loadDomainData', { domain: this.domain, mode: 'detailed' }).then(() => {
			if (!this.isBot) {
				this.$store.dispatch('loadDomainWebsiteInfo', { domain: this.domain })
			}
		})
		//this.$store.dispatch('loadDomainWhois', { domain: this.domain })
		this.$store.dispatch('loadDomainActivity', { domain: this.domain })

		if (!this.isBot) {
			this.$store.dispatch('loadDomainNs', { domain: this.domain })
			this.$store.dispatch('loadDomainDnsRecords', { domain: this.domain }).then(records => {
				this.$store.dispatch('loadIPsInfo', [...records.A, ...records.AAAA].map(record => record.value))
			})
		}

		fetch(`https://api.hunter.io/v2/email-count?domain=${this.domain}`)
			.then(response => response.json())
			.then(emails => {
				this.emailsHunter = emails.data
			})

		if (this.isStaff) {
			domainsApi(`domain/${this.domain}/incoming-emails`).then(({ data }) => {
				this.emailsIncoming.push(...data)
			})
		}

		if (this.betas.includes('emails')) {
			this.$store.dispatch('loadDomainEmails', { domain: this.domain })
		}
	},
	mounted() {
		this.$modalNote = new Modal(document.getElementById('modal-private-note'))

		if (this.$route.hash.includes('add-note')) {
			this.$modalNote.show()
			window.location.hash = ''
		}
	},
	methods: {
		isError(obj) {
			return obj instanceof Error
		},
		enrichContent,
		isIP(ip) {
			return ipRegex({exact: true}).test(ip)
		},

		toTime(seconds) {
			let txt = seconds + 's'

			if (seconds >= 86400) {
				const days = Math.round(seconds / 86400)
				txt = days + (days === 1 ? ' day' : ' days')
				txt = days + 'd'
			} else if (seconds >= 3600) {
				const hours = Math.round(seconds / 3600)
				txt = hours + (hours === 1 ? ' hour' : ' hours')
				txt = hours + 'h'
			} else if (seconds >= 60) {
				const minutes = Math.round(seconds / 60)
				txt = minutes + 'm'
			}

			return txt
		},

		addDomainActivity(data) {
			domainsApi.post(`domain/${this.domain}/activity`, data).then(({ data }) => {
				this.$store.commit('domainUpdateActivity', {
					domain: this.domain,
					data,
				})

				Vue.toasted.success('Note added')
				this.newNote.data = ''
				this.$modalNote.hide()
			}, error => {
				Vue.toasted.error(`Couldn't add the note ~ ${error}`)
			})
		},
		addPrivateNote() {
			this.addDomainActivity({
				data: JSON.stringify(this.newNote.data.trim()),
			})
		},
		addNote() {
			this.addDomainActivity({
				data: JSON.stringify(this.newNote.data.trim()),
				created_at: this.newNote.created_at + ' ' + this.newNote.created_at_time,
				type: 'note',
			})
		},
		addLink() {
			domainsApi.post(`domain/${this.domain}/activity-url`, { url: this.newLink }).then(({ data }) => {
				this.$store.commit('domainUpdateActivity', {
					domain: this.domain,
					data,
				})

				Vue.toasted.success('Link added')
				this.newlink = ''
			}, error => {
				Vue.toasted.error(`Couldn't add the link ~ ${error.message}`)
			})
		},
		deleteDomainActivity(activity) {
			if (confirm('For sure??')) {
				domainsApi.delete(`domain/${this.domain}/activity/${activity.id}`).then(() => {
					Vue.toasted.info('Activity deleted')
				}, error => {
					Vue.toasted.error(`Couldn't remove this activity ~ ${error}`)
				})
			}
		},
		whoisActivityAddCode(activity) {
			const expected_code = prompt('Code?')

			if (expected_code) {
				activity.expected_code = expected_code

				domainsApi.put(`domain/${this.domain}/activity/${activity.id}`, { expected_code }).then(() => {
					Vue.toasted.success('Whois Code added')
				}, error => {
					Vue.toasted.error(`Couldn't add activity code ~ ${error}`)
				})
			}
		}
	},
	watch: {
		$route(to, from) {
			console.log('Route', to.query, from.query)
		},
	},
}
</script>
