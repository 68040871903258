import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'
import Domains from '../components/Domains.vue'
import Domain from '../components/Domain.vue'
import DomainsList from '../components/DomainsList.vue'
import DomainsDatabase from '../views/Domains/List.vue'

import Tlds from '../views/Tlds/list.vue'
import Tld from '../views/Tlds/tld.vue'

import Lists from '../views/Lists/Lists.vue'
import ListsList from '../views/Lists/List.vue'

import Policies from '../components/Policies.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/domains',
    name: 'Domains',
    component: Domains,
    meta: {
      backTo: '/'
    }
  },
  {
    path: '/domains/:domain/:tab?',
    name: 'DomainOld',
    component: Domain,
    props: true,
    meta: {
      backTo($route) {
        return `/domains?q=${$route.params.domain}&d=${$route.params.domain}`
      }
    }
  },
  {
    path: '/tlds',
    name: 'TLDs',
    component: Tlds,
  },
  {
    path: '/tlds/:tld',
    name: 'TLD',
    component: Tld,
  },
  {
    path: '/domains-database',
    name: 'DomainsDatabase',
    component: DomainsDatabase,
    meta: {
      requiresAuth: true,
      backTo: '/'
    }
  },
  {
    path: '/domains-list',
    name: 'DomainsList',
    component: DomainsList,
    meta: {
      requiresAuth: true,
      backTo: '/'
    }
  },
  {
    path: '/collections',
    name: 'Lists',
    component: Lists,
    meta: {
      backTo: '/'
    }
  },{
    path: '/collections/:list/:tab?',
    name: 'ListsList',
    component: ListsList,
    meta: {
      backTo: '/collections'
    }
  },

  {
    path: '/account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      backTo: '/'
    },
    children: [
      {
        path: '',
        name: 'Account',
        component: () => import(/* webpackChunkName: "account" */ '../views/AccountInfo.vue'),
        meta: {
          requiresAuth: true,
          backTo: '/'
        },
      },
      {
        path: 'welcome',
        name: 'AccountWelcome',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Welcome.vue'),
        meta: {
          requiresAuth: true,
          backTo: '/welcome'
        },
      },
      {
        path: 'update',
        name: 'AccountInfo',
        component: () => import(/* webpackChunkName: "account" */ '../views/AccountUpdate.vue'),
        meta: {
          requiresAuth: true,
          backTo: '/account'
        },
      },
      {
        path: 'notifications',
        name: 'AccountNotifications',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/Notifications.vue'),
        meta: {
          requiresAuth: true,
          backTo: '/account'
        },
      },
      {
        path: 'plan-billing',
        name: 'AccountPlanBilling',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/PlanBilling.vue'),
        meta: {
          requiresAuth: true,
          backTo: '/account'
        },
      },{
        path: 'api-keys',
        name: 'AccountApiKeys',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account/ApiKeys.vue'),
        meta: {
          requiresAuth: true,
          backTo: '/account'
        },
      },
      {
        name: 'AccountLogin',
        path: 'sign-in',
        component: () => import(/* webpackChunkName: "account" */ '../views/AccountLogin.vue'),
        meta: {
          backTo: '/'
        },
      },
      {
        path: 'register',
        name: 'AccountRegister',
        component: () => import(/* webpackChunkName: "account" */ '../views/AccountRegister.vue')
      },
      {
        name: 'AccountResetPass',
        path: 'reset-pass',
        component: () => import(/* webpackChunkName: "account" */ '../views/AccountResetPass.vue'),
        meta: {
          backTo: '/account/sign-in'
        },
      },
      {
        name: 'AccountChangePass',
        path: 'change-password',
        component: () => import(/* webpackChunkName: "account" */ '../views/AccountChangePass.vue'),
        meta: {
          backTo: '/sign-in'
        },
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pages" */ '../views/About.vue'),
    meta: {
      backTo: '/'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "pages" */ '../views/Contact.vue'),
    meta: {
      backTo: '/'
    }
  },
  {
    path: '/features',
    name: 'Features',
    component: () => import(/* webpackChunkName: "pages" */ '../views/Features/Features.vue'),
    meta: {
      backTo: '/'
    }
  },
  {
    path: '/features/domain-search',
    name: 'FeatureSearch',
    component: () => import(/* webpackChunkName: "pages" */ '../views/Features/Search.vue'),
    meta: {
      backTo: '/features'
    }
  },
  {
    path: '/features/domain-monitoring',
    redirect: '/monitor-domains',
  },
  {
    path: '/monitor-domains',
    name: 'FeatureMonitoring',
    component: () => import(/* webpackChunkName: "pages" */ '../views/Features/Monitoring.vue'),
    meta: {
      backTo: '/features'
    }
  },
  {
    path: '/plans',
    name: 'Plans',
    component: () => import(/* webpackChunkName: "pages" */ '../views/Plans.vue'),
    meta: {
      backTo: '/'
    }
  },
  {
    path: '/extension',
    redirect: '/browser-extension',
  },
  {
    path: '/browser-extension',
    name: 'Extension',
    component: () => import(/* webpackChunkName: "features" */ '../views/Extension.vue'),
    meta: {
      backTo: '/'
    }
  },
  {
    path: '/policies/:policy?',
    component: Policies,
    meta: {
      backTo: '/'
    }
  },
  {
    path: '/stats',
    name: 'Stats',
    component: () => import(/* webpackChunkName: "stats" */ '../views/Stats.vue'),
  },
  {
    path: '/dash',
    component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/Dash.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/Overview.vue'),
      },
      {
        path: 'people',
        name: 'DashboardPeople',
        component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/People.vue'),
      },
      {
        path: 'lists',
        name: 'DashboardLists',
        component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/Lists.vue'),
      },
      {
        path: 'sales',
        name: 'DashboardSales',
        component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/Sales.vue'),
      },
      {
        path: 'keywords',
        name: 'DashboardKeywords',
        component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/Keywords.vue'),
      },
      {
        path: 'api-keys',
        name: 'DashboardApiKeys',
        component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/ApiKeys.vue'),
      },
      {
        path: 'servers',
        name: 'DashboardServers',
        component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/Servers.vue'),
      },
      {
        path: 'emails',
        name: 'DashboardEmails',
        component: () => import(/* webpackChunkName: "dash" */ '../views/Dash/Emails.vue'),
      },
    ]
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "pages" */ '../views/PageNotFound.vue')
  },
  {
    path: '/:domain([a-z0-9-]{1,64}[.]{1}[a-z.]{2,})/:tab?',
    name: 'Domain',
    component: Domain,
    props: true,
    meta: {
      backTo($route) {
        return `/domains?q=${$route.params.domain}&d=${$route.params.domain}`
      }
    }
  },
  {
    path: '*',
    redirect: '/not-found',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // don't move the page when selecting different domain in left sidebar
    if (to.path === from.path && to.path === '/domains') {
      return false
    }

    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // hide Modal or Offcanvas
  if (store.state.$modalPlan?._isShown) {
    store.state.$modalPlan.hide()
  }
  if (store.state.$modalMonitoring?._isShown) {
    store.state.$modalMonitoring.hide()
  }
  if (store.state.$modalPlans?._isShown) {
    store.state.$modalPlans.hide()
  }
  if (store.state.$offcanvasMenu?._isShown) {
    store.state.$offcanvasMenu.hide()
  }

  // check for auth
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.auth) {
    next({
      path: '/account/sign-in',
      query: { redirect: to.fullPath }
    })
  } else {
    if ((from.name || from.path) !== (to.name || to.path)) {
      //appEvent(`PageView "${to.name || to.path}"`, to.query)
    }

    next()
  }
});

export default router
