<template>
	<div class="tlds">

		<section class="page-head">
			<div class="container text-center py-5">
				<h4>Info about all TLDs</h4>
				<p>Discover helpful info about TLDs, like creation date, managing organization or availability</p>
			</div>
		</section>

		<section>

			<div class="container py-5">

				<div class="row align-items-center bg-light rounded mb-4">
					<div class="col-6 col-md-3">
						<select class="form-control" v-model="filters.availability">
							<option value="">All availability</option>
							<option value="general-availability">Available</option>
							<option value="sunrise">Sunrise</option>
							<option value="unknown">Unknown</option>
						</select>
					</div>
					<div class="col-6 col-md-3">
						<select class="form-control" v-model="filters.type">
							<option value="">All types</option>
							<option value="ccTLD">Country Code TLD</option>
							<option value="gTLD">Generic TLD</option>
							<option value="grTLD">Restricted generic TLD</option>
							<option value="sTLD">Sponsored TLD</option>
							<option value="IDN">IDN</option>
							<option value="test">Test</option>
							<option value="infrastructure">Infrastructure</option>
						</select>
					</div>
					<!--
					<div class="col-6 col-md-4">
						<label class="bg-white border d-block rounded py-1">[ ] Include second-level domains</label>
					</div>
					-->
				</div>

				<div class="row bg-light rounded py-3 mb-3">
					<div class="col-4">
						<strong @click="changeSort('tld')" class="cursor-pointer me-1">TLD</strong>
						<small v-if="pag.sortBy === 'tld'" class="text-muted">{{ pag.sortOrder === 'asc' ? '△' : '▽' }}</small>
					</div>
					<!-- <div class="col">
						<strong @click="changeSort('availability')" class="cursor-pointer me-1">Availability</strong>
						<small v-if="pag.sortBy === 'availability'" class="text-muted">{{ pag.sortOrder === 'asc' ? '△' : '▽' }}</small>
					</div> -->
					<div class="col">
						<strong @click="changeSort('type')" class="cursor-pointer me-1">Type</strong>
						<small v-if="pag.sortBy === 'type'" class="text-muted">{{ pag.sortOrder === 'asc' ? '△' : '▽' }}</small>
					</div>
					<div class="col">
						<strong @click="changeSort('domains_registered', 'desc')" class="cursor-pointer me-1">Total domains</strong>
						<small v-if="pag.sortBy === 'domains_registered'" class="text-muted">{{ pag.sortOrder === 'asc' ? '△' : '▽' }}</small>
					</div>
					<div class="col">
						<strong @click="changeSort('sales_30days', 'desc')" class="cursor-pointer me-1">Sales in last 30 days</strong>
						<small v-if="pag.sortBy === 'sales_30days'" class="text-muted">{{ pag.sortOrder === 'asc' ? '△' : '▽' }}</small>
					</div>
					<div class="col">
						<strong class="me-1">Organisation</strong>
					</div>
					<div class="col">
						<strong @click="changeSort('created')" class="cursor-pointer me-1">Created on</strong>
						<small v-if="pag.sortBy === 'created'" class="text-muted">{{ pag.sortOrder === 'asc' ? '△' : '▽' }}</small>
					</div>
				</div>

				<router-link v-for="tld in tlds" :key="tld.id" :to="`/tlds/${tld.tld}`" class="d-block mb-3">
					<div class="row align-items-center bg-white rounded py-3">
						<div class="col-4"><span style="font-size: 1.75rem"><strong class="text-primary">.</strong>{{ tld.tld }}</span></div>
						<!-- <div class="col"><span class="badge" :class="[tldStatuses[tld.availability].class]">{{ tldStatuses[tld.availability].name }}</span></div> -->
						<div class="col">{{ tld.type }}</div>
						<div class="col"><strong>{{ tld.domains_registered ? tld.domains_registered.toLocaleString() : '' }}</strong></div>
						<div class="col">
							<span v-if="tld.sales_30days">
								<strong>{{ tld.sales_30days }}</strong>
								
								<span v-if="tld.sales_prev30days">
									<small v-if="tld.sales_30days > tld.sales_prev30days" class="text-success"> +{{ ((tld.sales_30days - tld.sales_prev30days) / (tld.sales_prev30days) * 100).toFixed(1) }}%</small>
									<small v-else-if="tld.sales_30days < tld.sales_prev30days" class="text-danger"> {{ ((tld.sales_30days - tld.sales_prev30days) / (tld.sales_prev30days) * 100).toFixed(1) }}%</small>
									<small v-else-if="tld.sales_30days === tld.sales_prev30days" class="text-neutral"> 0%</small>
								</span>
							</span>
						</div>
						<div class="col"><small>{{ tld.organization.organisation }}</small></div>
						<div class="col">{{ new Date(tld.created).toLocaleDateString() }}</div>
					</div>
				</router-link>

				<div v-if="state === 'idle' && !tlds.length" class="text-center text-info bg-white rounded py-3 mb-3">
					No Top-Level Domains found for these filters..
				</div>

				<div v-if="state === 'loading'" class="text-center bg-white rounded py-3 mb-3">
					<div class="spinner-border" role="status"></div>
				</div>

				<div class="row my-4">
					<div class="col">
						<strong>{{ pag.total }}</strong> found<span v-if="pag.total > pag.perPage">, {{ pag.perPage }} per page</span>
					</div>
					<div class="col-auto">
						<ul class="pagination">
							<li v-for="p in pag.pages" :key="p" class="page-item" :class="{active: p === pag.page}"><span class="page-link cursor-pointer" @click="changePage(p)">{{ p }}</span></li>
						</ul>
					</div>
				</div>

			</div>

		</section>

	</div>
</template>

<script>
//import Search from '@/components/Search.vue'
import domainsApi from "@/api.js"
import { debounce } from 'vue-debounce'

export default {
	name: 'TLDs',
	metaInfo: {
		title: 'Browse TLDs',
	},
	data() {
		return {
			state:		'idle',
			filters:	{
				q:				this.$route.query.q ? this.$route.query.q.slice(1) : '',
				type:			this.$route.query.type || '',
				availability:	'',
			},
			pag:		{
				sortBy:		'domains_registered',
				sortOrder:	'desc',
				page:		1,
				perPage:	100,
				total:		0,
			},
			tlds:		[],
			tldStatuses: {
				'general-availability': {
					name: 'Available',
					class: 'bg-success-lighter text-success'
				},
				'unknown': {
					name: 'Unknown',
					class: 'bg-warning-lighter text-warning'
				},
			}
		}
	},
	created() {
		//console.log('TLDs', 'created')
		this.$store.dispatch('ev', { event: 'Load TLDs' })
	},
	mounted() {
		this.loadTLDs()
	},
	methods: {
		alert,
		loadTLDs() {
			this.state = 'loading'

			domainsApi.get(`tlds`, {params: {...this.pag, ...this.filters}}).then(({ data, headers }) => {
				//context.commit("mutateUserInfo", data);

				this.pag.total = parseInt(headers['x-total'], 10)
				this.pag.pages = Math.ceil(this.pag.total / this.pag.perPage)
				this.tlds.push(...data)
				this.state = 'idle'
			}, error => {
				this.state = 'error'
				console.log(error)
			});
		},
		changeSort(sortBy, defaultSort = 'asc') {
			if (sortBy === this.pag.sortBy) {
				this.pag.sortOrder = this.pag.sortOrder === 'asc' ? 'desc' : 'asc'
			} else {
				this.pag.sortBy = sortBy
				this.pag.sortOrder = defaultSort
			}

			this.pag.page = 1
			this.tlds = []
			this.loadTLDs()
		},
		changePage(page) {
			this.pag.page = page
			this.tlds = []
			this.loadTLDs()
		},
	},
	watch: {
		filters: {
			deep:	true,
			handler: debounce(function() {
				this.pag.page = 1
				this.tlds = []
				this.loadTLDs()
			}, 300)
		},
		$route() {
			this.filters.q = this.$route.query.q.slice(1)
		}
	}
}
</script>
