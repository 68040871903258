import axios from 'axios'
import store from './store'

const domainsApi = axios.create({
	baseURL: 'https://api.dmns.app/',
	//baseURL: 'https://domain-api-qoenb5pwba-ew.a.run.app/',
	//baseURL: 'http://localhost:808/',
	//withCredentials: true,
	//timeout: 1000,
	headers: {
		//'access-control-request-headers':	'link, x-total',
		//'X-Custom-Header': 'foobar',
	},
	params: {
		//serviceToken: '4f29d14b-70b8-4ce2-9bcc-12497ecfcdec'
	},
})

domainsApi.interceptors.request.use((config) => {
	if (store.state.token) {
		config.headers['Authorization'] = `Bearer ${store.state.token}`
	}

	return config
})

domainsApi.interceptors.response.use(undefined, (err) => {
	return new Promise(() => {
		if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
			store.dispatch('authLogout')
			alert(`Auth token not valid anymore`)
		}

		throw err
	})
})

export default domainsApi
